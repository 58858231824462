<template>
  <div id="app">
    <div class="mg-10"><el-input style="width: 200px" suffix-icon="el-icon-search" placeholder="请输入关键词" v-model="searkeyContent"></el-input>
      <el-button class="ml-5" type="primary" @click="load">查询</el-button>
      <el-button class="ml-5" type="warning" @click="reset">重置</el-button></div>
    <div class="mg-10">
      <el-button class="ml-5" type="primary" @click="addModel">新增设置</el-button>
    </div>
    <el-table :data="tableData" max-height="700px" border stripe  :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
      <el-table-column prop="keyWords" label="关键词" width="150">
      </el-table-column>
      <el-table-column prop="replyContent" label="回复内容" width="120">
      </el-table-column>
      <el-table-column prop="replyType" label="回复类型" width="180" :formatter="replyTypeFormatter" >
      </el-table-column>
      <el-table-column prop="ptType" label="应用平台" :formatter="ptTypeFormatter">
      </el-table-column>
<!--      <el-table-column prop="accounts" label="应用账号" :formatter="accountsFormatter">-->
<!--      </el-table-column>-->
      <el-table-column prop="defaultStatus" label="是否默认回复" :formatter="defaultStatusFormatter">
      </el-table-column>
      <el-table-column prop="enableStatus" label="启用/停用" :formatter="enableStatusFormatter">
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" :formatter="createTimeFormatter">
      </el-table-column>
      <el-table-column label="操作" width="210">
        <template slot-scope="scope">
          <el-button size="small" class="ml-5" type="primary" @click="editModel(scope.row)">编辑 <i class="el-icon-edit"></i></el-button>
          <el-popconfirm
              title="确定要删除吗？" @confirm="deleteData(scope.row.id)"
          >
            <el-button size="small" class="ml-5" type="danger" slot="reference">删除 <i class="el-icon-remove-outline"></i></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="pd-10">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <el-dialog :title="modelTitle" :visible.sync="dialogFormVisible" :close-on-click-modal="false" :close-on-press-escape="false" width="600px">
      <el-form :rules="rules" :model="addForm" ref="addForm" label-width="120px">
        <el-form-item prop="keyWords" label="关键词">
          <el-input v-model="addForm.keyWords"></el-input>
        </el-form-item>
        <el-form-item prop="replyContent" label="回复内容">
          <el-input v-model="addForm.replyContent"></el-input>
        </el-form-item>
        <el-form-item prop="matchType" label="匹配规则">
          <el-radio v-model="matchType" label='0'>模糊匹配</el-radio>
        </el-form-item>
        <el-form-item prop="replyType" label="回复类型">
          <el-radio-group v-model="addForm.replyType">
          <el-radio label='0'>回复评论</el-radio>
          <el-radio label='1'>回复私信</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="ptType" label="应用平台">
          <el-select v-model="addForm.ptType" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
<!--          <el-input v-model="addForm.ptType"></el-input>-->
        </el-form-item>
<!--        <el-form-item label="应用账号">-->
<!--          <el-input v-model="addForm.accounts"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="是否默认回复">
          <el-switch
              v-model="addForm.defaultStatus"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="是否启用">
          <el-switch
              v-model="addForm.enableStatus"
              >
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="saveData">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import filter from "@/utils/filter";

export default {
  name: "AutoReplySet",
  data(){
    return{
      matchType:'0',
      tableData: [],
      total:0,
      pageNum:0,
      pageSize:10,
      searkeyContent:"",
      dialogFormVisible:false,
      addForm:{
        // replyType:'0',
        ptType:'0',
        enableStatus:true
      },
      modelTitle:"新增设置",
      options: [{
        value: '0',
        label: '抖音'
      }],
      rules:{
        keyWords:[{required:true,message:"请输入关键词",trigger:'blur'}],
        replyContent: [{required:true,message:"请输入回复内容",trigger:'blur'}],
        replyType: [{required:true,message:"请选择回复类型",trigger:'change'}],
        ptType: [{required:true,message:"请选择应用平台",trigger:'change'}]
      }
    }
  },
  created(){
    this.load();
  },
  methods: {
    addModel(){
      this.dialogFormVisible=true;
      if(this.$refs['addForm']){
        this.$refs['addForm'].resetFields();
      }
      this.addForm={}
      // this.addForm.replyType='0'
      this.addForm.ptType='0'
      this.addForm.enableStatus=true;
    },
    editModel(data){
      this.dialogFormVisible=true;
      if(this.$refs['addForm']){
        this.$refs['addForm'].resetFields();
      }
      this.addForm=Object.assign({},data);
      this.modelTitle="编辑"
    },
    deleteData(id) {
      request.delete("keyWordsSet/" + id).then(res => {
        if (res) {
          if (res.code === '200') {
            this.$message.success("删除成功");
            this.load();
          } else {
            this.$message.error(res.msg);
          }
        }
      })
    },
    reset(){
      this.searkeyContent="";
      this.load();
    },
    load() {
      this.request.get("keyWordsSet/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          searkeyContent: this.searkeyContent
        }
      }).then(res => {
        if (res.code === '200') {
          this.tableData = res.data.records;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
        //console.log(res)
      })
    },
    saveData(){
      this.$refs['addForm'].validate((valid)=> {
        if (valid) {
          this.request.post("keyWordsSet/", this.addForm).then(res => {
            if (res.code === '200') {
              this.$message.success("保存成功")
              this.dialogFormVisible = false
              this.load();
            } else {
              this.$message.error(res.msg);
            }
          })
        } else {
          return false;
        }
      });
    },
    handleSizeChange(v) {
      // console.log(v)
      this.pageSize = v;
      this.load();
    },
    handleCurrentChange(v) {
      // console.log(v)
      this.pageNum = v;
      this.load();
    },
    replyTypeFormatter(row, column) {
      let value = row.replyType;
      if (value == '0') {
        return '回复评论';
      } else if (value == '1') {
        return '回复私信';
      }
    },
    ptTypeFormatter(row, column) {
      let value = row.ptType;
      if (value === "0") {
        return '抖音';
      } else if (value === 1) {
        return '快手';
      } else if (value === 2) {
        return '西瓜';
      } else if (value === 3) {
        return '视频号';
      } else if (value === 4) {
        return '百家号';
      } else if (value === 5) {
        return '小红书';
      } else if (value === 6) {
        return '微博';
      } else {
        return value;
      }
    },
    defaultStatusFormatter(row){
      let value = row.defaultStatus;
      if (value) {
        return '是';
      } else {
        return '否';
      }
    },
    enableStatusFormatter(row){
      let value = row.enableStatus;
      if (value) {
        return '启用';
      } else {
        return '停用';
      }
    },
    createTimeFormatter(row, column){
      return filter.formatDate(row.createTime,"YYYY-MM-DD HH:mm:ss")
    },
  }
}
</script>

<style scoped>

</style>