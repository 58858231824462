<template>
  <div id="app">
      <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="抖音" name="first">
              <DyVideoState></DyVideoState>
          </el-tab-pane>
          <el-tab-pane label="快手" name="second">
            <KsVideoState></KsVideoState>
          </el-tab-pane>
      </el-tabs>

  </div>
</template>

<script>

import DyVideoState from "@/components/statistics/DyVideoState";
import KsVideoState from "@/components/statistics/KsVideoState";

export default {
  name: "VideoStat",
    components: {KsVideoState, DyVideoState},
    data(){
        return{
            activeName:"first"
        }},
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        }
    },
}
</script>

<style scoped>

</style>