<template>
  <div id="list">
  	<ul>
  		<li v-for="(item,i) in sessions" :class="{ active: item.id === currentSession.id }" v-on:click="changeCurrentSession(item)"><!--   :class="[item.id === currentSession ? 'active':'']" -->
        <el-badge  is-dot v-if="judgeRedPotShow(item)">
          <el-avatar class="avatar" :src="item.avatar?item.avatar:defAvatar" :alt="item.name" shape="circle"></el-avatar>
        </el-badge>
        <el-avatar v-else class="avatar" :src="item.avatar?item.avatar:defAvatar" :alt="item.name" shape="circle"></el-avatar>

<!--        <el-badge :value="1"><el-avatar class="avatar" :src="item.avatar?item.avatar:this.defAvatar" :alt="item.name"></el-avatar>-->
<!--        </el-badge>-->
        <div class="divCss">
        <p class="name">{{nameFormatter(item)}}</p>
          <p class="msgCss">{{contentFormatter(item)}}</p>
        </div>
        <div class="timeCss">{{sendTimeFormatter(item)}}</div>
  		</li>
  	</ul>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import filter from "@/utils/filter";

export default {
  name: 'list',
  data () {
    return {
      defAvatar:'https://p26-passport.byteacctimg.com/img/motor-img/a43822fd5527b4ed2f30328a772f6b62~noop.jpg',
        defNickName:'昵称获取中'
    }
  },
  computed: mapState([
  'sessions',
  'currentSession'
	]),
  methods:{
    judgeRedPotShow(item){
      if(item.messages&&item.messages.length>0){
        if(item.messages[item.messages.length-1].readStatus===0){
          return true;
        }else{
          return false;
        }
      }else{
        return false;
      }
    },
    nameFormatter(item){
      if(item.name&&item.name.length>0){
        let msg =  item.name;
        if(msg.length>10){
          msg = msg.substring(0,8)+"...";
        }
        return msg;
      }else{
        return '昵称获取中';
      }
    },
    contentFormatter(item){
      if(item.messages&&item.messages.length>0){
          let msg =  item.messages[item.messages.length-1].content;
           if(msg&&msg.length>16){
               msg = msg.substring(0,16)+"...";
           }
        return msg;
      }else{
        return '';
      }
    },
    sendTimeFormatter(item){
      if(item.messages&&item.messages.length>0){
        let sendTime=item.messages[item.messages.length-1].sendTime;
        return this.timeFormatter(sendTime,"yyyy/MM/dd hh:mm");
      }else{
        return '';
      }
    },
    timeFormatter(time,format){
      return filter.timeChange(new Date(time*1000),format)
    },
  	changeCurrentSession:function (currentSession) {
  		this.$store.commit('changeCurrentSession',currentSession)
  	}
  }
}
</script>

<style lang="scss" scoped>
#list {
  height: 100%;
    ul{
        padding-left: 0px;height: 72%;overflow-y: auto
    }
	li {
    position: relative;
    height: 60px;
    font-size: 14px;
		padding: 15px 15px;
		border-bottom: 1px solid #292C33;
		cursor: pointer;
		&:hover {
			background-color: rgba(255, 255, 255, 0.03);
		}
	}
  li.active {/*注意这个是.不是冒号:*/
			background-color: rgba(255, 255, 255, 0.1);
	}
	.avatar {
		//border-radius: 2px;
		width: 30px;
		height: 30px;
    margin-top: 0px;
		vertical-align: middle;
	}
	.name {
		//display: inline-block;
		margin-bottom: 0px;
	}
  .msgCss {
    margin-top: 5px;
    font-size: 13px;
    color: #909399;
  }

  .divCss {
    position: absolute;
    top: -5px;
    left: 60px
  }
  .potCss{
    margin-left: 20px;font-size: 12px;color: #909399;
  }
  .timeCss{
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 12px;color: #909399;
  }
}
</style>
