import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Login";
import Chat from "@/views/chat/Chat";
import User from "@/views/User";
import Manage from "@/views/Manage";
import Home from "@/views/Home";
import AccountBook from "@/views/chat/AccountBook";
import ChatList from "@/views/chat/ChatList";
import CommentList from "@/views/chat/CommentList";
import Comment from "@/views/chat/Comment";
import AutoReplySet from "@/views/AutoReplySet";
import AutoReplyRecord from "@/views/AutoReplyRecord";
import NoticeSet from "@/views/NoticeSet";
import UploadVideo from "@/views/publish/UploadVideo";
import FansStat from "@/views/statistics/FansStat";
import VideoState from "@/views/statistics/VideoState";
import AuthSuccess from "@/views/AuthSuccess";
import AuthFailure from "@/views/AuthFailure";

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    icon:'el-icon-s-custom',
    name: '账号管理',
    component: Manage,
    redirect:"/login",
    children: [{
      path: '/account/account_auth',
      name: '账号授权',
      component: User
    }],
  }
  // ,
  // {
  //   path: '/upload/upload_video',
  //   name: '智能发布',
  //   component: UploadVideo,
  //   hidden:true
  // }
  ,{
    path: '/',
    icon:'el-icon-s-platform',
    name: 'CRM管理',
    component:Manage,
    redirect:"/login",
    children:[
        {
      path: '/chat/direct_list',
      name: '私信消息',
      component: ChatList
    },{
        path: '/chat/comment_list',
        name: '评论消息',
        component: CommentList
      }
      ,{
        path: '/chat/auto_reply_set',
        name: '自动回复设置',
        component: AutoReplySet,
      }
      ,{
        path: '/chat/auto_reply_record',
        name: '自动回复记录',
        component: AutoReplyRecord,
      }
      ,{
        path: '/chat/notice_set',
        name: '通知配置',
        component: NoticeSet,
      }
      ,{
        path: '/chat/chat',
        name: '私信管理',
        component: Chat,
        hidden:true
      },
      {
        path: '/chat/comment',
        name: '评论管理',
        component: Comment,
        hidden:true
      }
    ]
  },
  {
    path: '/',
    icon:'el-icon-s-data',
    name: '数据分析',
    component:Manage,
    redirect:"/login",
    children:[
      {
        path: '/statistics/fans_stat',
        name: '粉丝数据',
        component: FansStat,
      },{
        path: '/statistics/video_stat',
        name: '视频数据',
        component: VideoState,
      }
    ]
  }
  ,{
    path: '/login',
    name: '登录',
    component: Login,
    hidden:true
  },
  {
    path: '/auth_success',
    name: '授权成功',
    component: AuthSuccess,
    hidden:true
  },
  {
    path: '/auth_failure',
    name: '授权失败',
    component: AuthFailure,
    hidden:true
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
