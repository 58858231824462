<template>
    <el-menu :default-openeds="['0']" style="height: 100%;overflow-x:hidden; overflow-y: auto"
             background-color="rgb(48,65,86)" text-color="#fff"
             active-text-color="#ffd04b"
             :collapse-transition="false"
             :collapse="isCollapse"
             router
             @select="handleSelect" unique-opened
    >
        <div style="height: 60px;line-height: 60px;text-align: center">
            <img src="../assets/logo.png" style="width: 30px;position:relative;top:5px;right: 5px">
            <b style="color:white; font-size: 18px" v-show="logoTextShow">短视频矩阵系统</b>
        </div>
<!--      <el-menu-item-group>-->
<!--        <el-menu-item index="2-1">账号管理</el-menu-item>-->
<!--      </el-menu-item-group>-->
        <el-submenu :index="i" v-for="(item,i) in this.$router.options.routes" v-if="!item.hidden" :key="i">
            <template slot="title">
              <i :class="item.icon"></i>
              <span slot="title">{{item.name}}</span>
            </template>
          <el-menu-item :index="children.path" v-for="(children,j) in item.children" v-if="!children.hidden">
<!--            <i class="el-icon-s-custom"></i>-->
            <span slot="title">{{children.name}}</span>
          </el-menu-item>

        </el-submenu>

    </el-menu>
</template>

<script>
    export default {
        name: "Aside",
        props:{
            isCollapse:Boolean,
            logoTextShow: Boolean
        },
      methods:{
          handleSelect(index){
              console.log(index)
            // console.log(this.$router.options.routes)
            // this.router.push(index)
          }
      }
    }
</script>

<style scoped>

</style>