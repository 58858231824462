import moment from 'moment'
const filter = {
    formatDate: function(value,args) {
        return moment.utc(value).format(args);
    },
    formatDateForTime(date, fmt) {
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
        }
        let o = {
            'M+': date.getMonth() + 1,
            'd+': date.getDate(),
            'h+': date.getHours(),
            'm+': date.getMinutes(),
            's+': date.getSeconds()
        }
        for (let k in o) {
            if (new RegExp(`(${k})`).test(fmt)) {
                let str = o[k] + ''
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : this.padLeftZero(str))
            }
        }
        return fmt
    },
    padLeftZero(str) {
        return ('00' + str).substr(str.length)
    },
    timeChange(timeValue) {
        timeValue = new Date(timeValue).getTime()
        var timeNew = new Date().getTime() // 当前时间
        // console.log('传入的时间', timeValue, timeNew)
        var timeDiffer = timeNew - timeValue // 与当前时间误差
        // console.log('时间差', timeDiffer)
        var returnTime = ''
        if (timeDiffer <= 60000) { // 一分钟内
            returnTime = '刚刚'
        } else if (timeDiffer > 60000 && timeDiffer < 3600000) { // 1小时内
            returnTime = Math.floor(timeDiffer / 60000) + '分钟前'
        } else if (timeDiffer >= 3600000 && timeDiffer < 86400000 && this.isYestday(timeValue) === false) { // 今日
            returnTime = this.formatTime(timeValue).substr(11, 5)
        } else if (timeDiffer > 3600000 && this.isYestday(timeValue) === true) { // 昨天
            returnTime = '昨天' + this.formatTime(timeValue).substr(11, 5)
        } else if (timeDiffer > 86400000 && this.isYestday(timeValue) === false && this.isYear(timeValue) === true) { // 今年
            returnTime = this.formatTime(timeValue).substr(5, 11)
        } else if (timeDiffer > 86400000 && this.isYestday(timeValue) === false && this.isYear(timeValue) === false) { // 不属于今年
            returnTime = this.formatTime(timeValue).substr(0, 16)
        }
        return returnTime
    },
    isYestday (timeValue) { // 是否为昨天
        const date = new Date(timeValue)
        const today = new Date()
        if (date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth()) {
            if (date.getDate() - today.getDate() === 1) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    },
    isYear(timeValue) { // 是否为今年
        const dateyear = new Date(timeValue).getFullYear()
        const toyear = new Date().getFullYear()
        // console.log(dateyear, toyear)
        if (dateyear === toyear) {
            return true
        } else {
            return false
        }
    },
    formatTime(date) {
        var t = this.getTimeArray(date)
        return [t[0], t[1], t[2]].map(this.formatNumber).join('-') + ' ' + [t[3], t[4], t[5]].map(this.formatNumber).join(':')
    },
    getTimeArray(date) {
        date = new Date(date)
        var year = date.getFullYear()
        var month = date.getMonth() + 1
        var day = date.getDate()
        var hour = date.getHours()
        var minute = date.getMinutes()
        var second = date.getSeconds()
        return [year, month, day, hour, minute, second].map(this.formatNumber)
    },
// 转化日期 如2018-7-6 -->(2018-07-06)
    formatNumber(n) {
        n = n.toString()
        return n[1] ? n : '0' + n
    },
    // 将匹配结果替换表情图片
    emotion (res) {
    let word = res.replace(/\#|\;/gi,'')
    const list = ['微笑', '撇嘴', '色', '发呆', '得意', '流泪', '害羞', '闭嘴', '睡', '大哭', '尴尬', '发怒', '调皮', '呲牙', '惊讶', '难过', '酷', '冷汗', '抓狂', '吐', '偷笑', '可爱', '白眼', '傲慢', '饥饿', '困', '惊恐', '流汗', '憨笑', '大兵', '奋斗', '咒骂', '疑问', '嘘', '晕', '折磨', '衰', '骷髅', '敲打', '再见', '擦汗', '抠鼻', '鼓掌', '糗大了', '坏笑', '左哼哼', '右哼哼', '哈欠', '鄙视', '委屈', '快哭了', '阴险', '亲亲', '吓', '可怜', '菜刀', '西瓜', '啤酒', '篮球', '乒乓', '咖啡', '饭', '猪头', '玫瑰', '凋谢', '示爱', '爱心', '心碎', '蛋糕', '闪电', '炸弹', '刀', '足球', '瓢虫', '便便', '月亮', '太阳', '礼物', '拥抱', '强', '弱', '握手', '胜利', '抱拳', '勾引', '拳头', '差劲', '爱你', 'NO', 'OK', '爱情', '飞吻', '跳跳', '发抖', '怄火', '转圈', '磕头', '回头', '跳绳', '挥手', '激动', '街舞', '献吻', '左太极', '右太极']
    let index = list.indexOf(word)
        if(index<0) return false;
    return `<img src="https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${index}.gif" style="vertical-align: sub;padding-top: 3px" align="middle">`
}
}
export default filter;
