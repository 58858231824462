<template>
    <el-row :gutter="2" style="height: 100%">
        <el-col :span="4" class="leftElRow">
            <div v-loading="loading">
                <el-row>
                    <el-col v-for="(item, index) in accountList" :key="item" class="card">
                        <el-card>
                            <el-link @click="accClick(item.id)" :underline="false" class="text-center">
                                <!--                <img :src="item.headImg" alt="...">-->
                                <el-avatar class="avatar" :src="item.headImg" :alt="item.name" shape="circle"></el-avatar>
                                <div class="card-footer">
                                    {{item.name.length>9?item.name.substring(0,9)+"...":item.name }}
                                </div>
                            </el-link>
                        </el-card>
                    </el-col>
                </el-row>
            </div>
            <!--left layout end -->
        </el-col>
        <el-col :span="20">
            <div>
                <el-row class="elRow">
                    <el-button :disabled="!hasMore" size="mini" type="primary" plain @click="nextPageClick()">下一页</el-button>
                    <el-button size="mini" type="primary" plain @click="plusDelete()">批量删除</el-button>
                  <div class="mg-10">
                    <el-form :inline="true" size="mini" class="demo-form-inline">
                      <!--                        <el-form-item label="行数：">-->
                      <!--                        </el-form-item>-->
                      <el-form-item label="行数：第">
                        <el-input style="width: 80px" size="mini" type="number" min="0" max="100000" v-model="exportStartNum"></el-input>
                      </el-form-item>
                      <el-form-item label="至">
                        <el-input style="width: 80px" size="mini" type="number" min="0" max="100000" v-model="exportEndNum"></el-input>
                      </el-form-item>
                      <el-form-item label="行">
                      </el-form-item>
                      <el-form-item label="发布时间:">
                        <el-date-picker
                            size="small"
                            v-model="selDateV"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="timestamp"
                        >
                        </el-date-picker>
                      </el-form-item>
                      <el-form-item label="">
                        <download-excel class="export-excel-wrapper" :data="exportData" :fields="jsonFields" :header="title" name="快手短视频数据.xls">
                          <el-button size="mini" type="primary" plain @click="doExportData(0)">部分导出</el-button>
                          <el-button size="mini" type="primary" plain @click="doExportData(1)">全部导出</el-button>
                        </download-excel>
                      </el-form-item>
                    </el-form>
                  </div>
                </el-row>
                <el-table max-height="800px" :data="tableData" border stripe  :header-cell-style="{'text-align':'center'}"
                          :cell-style="{'text-align':'center'}" @selection-change="handleSelectionChange">
                    <el-table-column
                            type="selection"
                            width="55">
                    </el-table-column>
                    <el-table-column prop="caption" label="作品标题" width="260px">
                        <template slot-scope="scope">
                            <el-link :href="scope.row.play_url" :underline="false" target="_blank">{{scope.row.caption}}</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column prop="cover" label="作品封面">
                        <template slot-scope="scope">
                            <el-image :src="scope.row.cover" style="width: 60px;" :preview-src-list="[scope.row.cover]">
                            </el-image>
                        </template>
                    </el-table-column>
<!--                    <el-table-column prop="create_time" label="发布时间" :formatter="dateFormatter"></el-table-column>-->
                  <el-table-column prop="createTimeStr" label="发布时间" ></el-table-column>
<!--                    <el-table-column prop="video_status" label="作品状态" :formatter="videoStatusFormatter"></el-table-column>-->
<!--                    <el-table-column prop="is_top" label="是否置顶" :formatter="isTopFormatter"></el-table-column>-->
                    <el-table-column prop="view_count" label="播放数" ></el-table-column>
                    <el-table-column prop="like_count" label="点赞数"></el-table-column>
<!--                    <el-table-column prop="share_count" label="分享数" :formatter="stateFormatter"></el-table-column>-->
<!--                    <el-table-column prop="forward_count" label="转发数" :formatter="stateFormatter"></el-table-column>-->
                    <el-table-column prop="comment_count" label="评论数"></el-table-column>
<!--                    <el-table-column prop="download_count" label="下载数" :formatter="stateFormatter"></el-table-column>-->
                </el-table>
                <div class="pd-10">
<!--                  layout="total, sizes, prev, pager, next, jumper"-->
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="pageNum"
                            :page-sizes="[1000000]"
                            :page-size="pageSize"
                            layout="total"
                            :total="total">
                    </el-pagination>
                </div>
            </div>
        </el-col>
    </el-row>
</template>

<script>
    import filter from "@/utils/filter";
    import request from "@/utils/request";

    export default {
        name: "KsVideoState",
        data(){
            return{
                multipleSelection: [],
              selDateV:'',
              exportStartNum:0,
              exportEndNum:0,
                loading:true,
                accountId:0,
                cursor:"",
                accountList:[],
                tableData: [],
              exportData:[],
                total:0,
                pageNum:0,
                pageSize:30,
                hasMore:false,
                detailForm:[],
                jsonFields:{
                    "作品标题":"caption",
                    "作品链接":"play_url",
                  "发布时间":"createTimeStr",
                    "播放数":"view_count",
                    "点赞数":"like_count",
                    // "分享数":"statistics.share_count",
                    // "转发数":"statistics.forward_count",
                    "评论数":"comment_count",
                    // "下载数":"statistics.download_count",
                },
                title:"标题",
            }
        },
        created(){
            this.loadAccount();
            // this.load();
        },
        methods: {
            handleSelectionChange(val) {
                console.log(JSON.stringify(val))
                this.multipleSelection = val;
            },
            plusDelete(){
              if(!this.multipleSelection||this.multipleSelection.length<=0){
                  this.$message.warning("请勾选要删除的行");
                  return;
              }
                this.$confirm('确定要删除吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let deleteIds = [];
                    for (let i in this.multipleSelection) {
                        let item = this.multipleSelection[i];
                        deleteIds.push(item.photo_id);
                    }
                    this.request.post("statistics/deleteVideos",{
                        accountId: this.accountId,
                        deleteIds:deleteIds,
                        ptType:1
                    }).then(res=>{
                        if (res.code === '200') {
                            this.$message.success('删除成功');
                            this.accClick(this.accountId)
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
          doExportData(val){
            if(val===1){
              this.exportData = this.tableData;
            }else{
              if(this.exportStartNum>this.exportEndNum){
                this.$message.warning("导出起始数大于截止数，将导出全部数据");
              }
              if(this.exportEndNum<=0||this.exportStartNum>this.exportEndNum){
                this.exportData = this.tableData;
              }else{
                this.exportData = this.tableData.slice(parseInt(this.exportStartNum)-1,parseInt(this.exportEndNum));
              }
              //根据日期筛选
              if(this.selDateV&&this.selDateV.length>0){
                let sdv = this.selDateV;
                this.exportData = this.exportData.filter(function(item) {
                  return item.create_time>=sdv[0]&&item.create_time<=sdv[1]
                });
              }
            }
            // console.log("导出数据："+this.exportData.length)
          },
            accClick(v){
                this.accountId=v;
                this.cursor='';
                this.load();
            },
            dateFormatter(row, column){
                return filter.formatTime(new Date(row.create_time))
            },
            reset() {
                this.load();
            },
            nextPageClick(){
                this.load();
            },
            loadAccount(){
                this.request.get("account/getMyAccountListByPt?pt=1").then(res => {
                    if (res) {
                        if (res.code === '200') {
                            this.loading=false;
                            this.accountList= res.data;
                        } else {
                            this.$message.error(res.msg);
                        }
                    }
                })
            },
            load(){
                this.request.get("statistics/ksVideoPage", {
                    params: {
                        accountId: this.accountId,
                        cursor: this.cursor,
                    }
                }).then(res => {
                  if (res.code === '200') {
                    let data = res.data;
                    if (data && data.length > 0) {
                      if (this.cursor != '' && this.tableData && this.tableData.length > 0) {
                        for (let i in data) {
                          this.tableData.push(data[i]);
                        }
                        this.total += data.length;
                      } else {
                        this.tableData = data;
                        this.total = data.length;
                      }
                      var cursor = data[data.length - 1].photo_id;
                      this.cursor = cursor;
                      this.hasMore = true;
                    } else {
                      this.tableData = [];
                      this.hasMore = false;
                    }
                  } else {
                    this.$message.error(res.msg);
                  }
                    //console.log(res)
                })
            },
            handleSizeChange(v) {
                // console.log(v)
                // this.pageSize = v;
                // this.load();
            },
            handleCurrentChange(v) {
                // console.log(v)
                // this.pageNum = v;
                // this.load();
            },
        }
    }
</script>

<style scoped>
    .avatar {
    //border-radius: 2px;
        width: 50px;
        height: 50px;
    //margin-left: 15px;
        margin-top: 0px;
        vertical-align: middle;
    }
    .leftElRow{
        /*border: 1px solid red;*/
        height: 100%;
        overflow-y: auto;
    }

    .elRow{
        margin-bottom: 20px;
    }
    .card{
        float: left; margin-left: 10px;margin-top: 10px;width: 100px;
    }
    .card-img-top{
        width: 60px;
    }
    .card-footer{
        padding-top: 5px;
        width: 60px;
        height: 22px;
        text-align: center;
        font-size: 12px;
    }
    .export-excel-wrapper{
        display: inline-block;
        margin-left: 10px;
    }
</style>