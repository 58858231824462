<template>
    <div id="app">
        <div class="mg-10"><el-input style="width: 200px" suffix-icon="el-icon-search" placeholder="请输入名称" v-model="name"></el-input><el-button class="ml-5" type="primary" @click="load">查询</el-button></div>
        <div class="mg-10">
          <el-dropdown>
            <el-button type="primary">
              账号授权<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="addModel(0)">抖音授权</el-dropdown-item>
              <el-dropdown-item @click.native="addModel(1)">快手授权</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <span style="font-size: 13px;color: firebrick;padding-left: 5px">(tips:抖音私信聚合能力目前仅面向企业号开放，可授权范围：认证企业号及其员工号和小程序品牌号及员工号)</span>
        </div>
        <div>抖音[已上/可上：{{dyAuthCount}}/{{dyMaxCount}}]</div>
      <div style="margin-bottom: 5px">快手[已上/可上：{{ksAuthCount}}/{{ksMaxCount}}]</div>
        <el-table :data="tableData" max-height="700px" border stripe :header-cell-style="{'text-align':'center'}" :cell-style="tabCellStyle">
          <el-table-column prop="name" label="昵称" width="150">
          </el-table-column>
          <el-table-column prop="platformType" label="平台" width="120" :formatter="ptFormatter">
          </el-table-column>
          <el-table-column prop="accountType" label="账号类型" width="120" :formatter="atFormatter">
          </el-table-column>
          <el-table-column prop="tokenExpireTime" label="授权到期时间" width="180" :formatter="tokenExpireTimeFormatter" >
            </el-table-column>
            <el-table-column prop="loginStatus" label="授权状态" :formatter="loginStatusFormatter">
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
<!--                    <el-button class="" type="primary">编辑 <i class="el-icon-edit"></i></el-button>-->
                    <el-popconfirm
                            title="确定要删除吗？" @confirm="deleteData(scope.row.id)"
                    >
                    <el-button type="danger" slot="reference">删除 <i class="el-icon-remove-outline"></i></el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <div class="pd-10">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[5, 10, 15, 20]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </div>
        <el-dialog @closed="closedCallback" v-if="isShowDyDialog" title="新增抖音授权" :visible.sync="dialogFormVisible" :close-on-click-modal="false" :close-on-press-escape="false" width="600px">
            <div style="padding-bottom: 10px">授权即代表您同意通过闯云宝平台：</div>
          <div class="divAutoTips"><span class="el-icon-success autoTips"></span>获取您的抖音账号信息、视频数据、粉丝数据、评论等</div>
          <div class="divAutoTips"><span class="el-icon-success autoTips"></span>往您的抖音账号发布公开作品 (发布内容、频次由您通过闯云宝平台操作设置)</div>
          <div class="divAutoTips"><span class="el-icon-success autoTips"></span>删除您抖音账号下的发布内容(由您通过闯云宝平台操作确认)</div>
            <div class="divCompanyAutoTips">如需使用以下功能请进行勾选</div>
          <div class="divAutoTips"><el-checkbox v-model="directChecked">私信功能(仅支持认证企业号及其员工号和小程序品牌号及员工号)</el-checkbox></div>

            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="btnAutoOK">确 定</el-button>
            </div>
        </el-dialog>
      <el-dialog @closed="closedCallback" v-else title="新增快手授权" :visible.sync="dialogFormVisible" :close-on-click-modal="false" :close-on-press-escape="false" width="600px">
        <div style="padding-bottom: 10px">授权即代表您同意通过闯云宝平台：</div>
        <div class="divAutoTips"><span class="el-icon-success autoTips"></span>获取您的快手账号信息、视频数据、评论等</div>
        <div class="divAutoTips"><span class="el-icon-success autoTips"></span>往您的快手账号发布公开作品 (发布内容、频次由您通过闯云宝平台操作设置)</div>
        <div class="divAutoTips"><span class="el-icon-success autoTips"></span>删除您快手账号下的发布的内容(由您通过闯云宝平台操作确认)</div>
<!--        <div class="divCompanyAutoTips">如需使用以下功能请进行勾选</div>-->
<!--        <div class="divAutoTips"><el-checkbox v-model="directChecked">私信功能(仅支持认证企业号)</el-checkbox></div>-->

        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
          <el-button size="small" type="primary" @click="btnAutoOK">确 定</el-button>
        </div>
      </el-dialog>
      <el-dialog id="elDialog" ref="myDialog" title="账号授权" :visible.sync="dialogAuto" :close-on-click-modal="false" :close-on-press-escape="false" width="780px">
<!--        <div >-->
          <iframe :src="authUrl" id="map" frameborder="0" width="100%" height="600px"  :key="key"></iframe>
<!--        </div>-->

      </el-dialog>
    </div>
</template>

<script>
    import filter from "@/utils/filter";
    import request from "@/utils/request";
    import common from "@/utils/common";

    export default {
        name: "User",
        data(){
            return{
              key: 0,
              isShowDyDialog:true,
                tableData: [],
                total:0,
                pageNum:0,
                pageSize:10,
                name:"",
              userInfo:localStorage.getItem("userInfo")?JSON.parse(localStorage.getItem("userInfo")):{},
              dyAuthCount:0,
              dyMaxCount:0,
              ksAuthCount:0,
              ksMaxCount:0,
              dialogFormVisible:false,
              directChecked:false,
              dialogAuto:false,
              authUrl:"",
            }
        },
      // onMounted(){
      //     window.addEventListener('message', this.payEvent)
      // },
      // message 该事件通过或者从对象(WebSocket, Web Worker, Event Source 或者子 frame 或父窗口)接收到消息时触发

      created(){
            this.load();
        },

        methods: {
          closedCallback(){
            this.load();
          },
          btnAutoOK() {
              this.key+=1;
            if(this.isShowDyDialog){
              this.dyAuth()
            }else{
              this.ksAuth()
            }

          },
            addModel(v){
            if(v==0){
              this.isShowDyDialog=true
            }
              if(v==1){
                this.isShowDyDialog=false
              }
                this.dialogFormVisible=true;
            },
            deleteData(id) {
                request.delete("account/"+id).then(res=>{
                    if(res){
                        if(res.code==='200'){
                            this.$message.success("删除成功");
                            this.load();
                        }else{
                            this.$message.error(res.msg);
                        }
                    }
                })
            },
          // base64UrlEncode(str) {
          //   let base64 = btoa(str);
          //   return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
          // },
          dyAuth() {
            var fromurl = "https://server.1-123.net/callback/accountAuthCallback";
            var stateJson = {};
            stateJson.userId = this.userInfo.userId;
            stateJson.platform = 0;
            stateJson.platformType = 0;
            stateJson.accountType = this.directChecked?1:0;
            let state = common.base64UrlEncode(encodeURIComponent(JSON.stringify(stateJson))); // encode后拼接到授权链接上 ,user.intention,im.direct_message,im.microapp_card,tool.image.upload,im.message_card
            var url="https://open.douyin.com/platform/oauth/connect/?client_key=aw62rc3o61yi42it&state="+state+"&response_type=code&scope=fans.data.bind,data.external.user,data.external.item,user_info,video.list.bind,video.data.bind,item.comment";
            if(this.directChecked){
              url+=",im.direct_message,im.microapp_card,tool.image.upload,im.message_card";
            }
            url+="&redirect_uri="+encodeURIComponent(fromurl);
            this.authUrl=url;
            this.dialogAuto=true;
            // window.open(url,"blank");
          },
          ksAuth(){
            var fromurl = "https://server.1-123.net/callback/accountAuthCallback";
            var stateJson = {};
            stateJson.userId = this.userInfo.userId;
            stateJson.platform = 0;
            stateJson.platformType = 1;
            stateJson.accountType = 0;
            let state = common.base64UrlEncode(encodeURIComponent(JSON.stringify(stateJson))); // encode后拼接到授权链接上
            var url = "https://open.kuaishou.com/oauth2/connect?app_id=ks687080419384462650&scope=user_info,user_video_info,user_video_delete&response_type=code&ua=pc&state="+state+"&redirect_uri="+encodeURIComponent(fromurl);
            // this.authUrl="/auth_success?accountType=0&ptType=1&name=aaaaaaa&headImg=https://p3.douyinpic.com/aweme/100x100/aweme-avatar/mosaic-legacy_729c0014c7b2d0cf91b1.jpeg?from=4010531038";
            this.authUrl=url;
            this.dialogAuto=true;
            // window.open(url,"blank");
          },
          load() {
            this.request.get("account/getAccountNum").then(res => {
              if (res.code === '200') {
                if(res.data){
                  this.dyAuthCount = res.data.dyAuthNum;
                  this.dyMaxCount = res.data.dyMaxNum;
                  this.ksAuthCount = res.data.ksAuthNum;
                  this.ksMaxCount = res.data.ksMaxNum;
                }
              } else {
                this.$message.error(res.msg);
              }
              //console.log(res)
            })
            this.request.get("account/page", {
              params: {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                name: this.name
              }
            }).then(res => {
              if (res.code === '200') {
                this.tableData = res.data.records;
                this.total = res.data.total;
              } else {
                this.$message.error(res.msg);
              }
              //console.log(res)
            })
          },
          handleSizeChange(v) {
            // console.log(v)
            this.pageSize = v;
            this.load();
          },
          handleCurrentChange(v) {
            // console.log(v)
            this.pageNum = v;
            this.load();
          },
          tokenExpireTimeFormatter(row, column){
            return filter.formatDate(row.tokenExpireTime,"YYYY-MM-DD HH:mm:ss")
          },
          loginStatusFormatter(row, column) {
            let value = row.loginStatus;
            if (value === 0) {
              return '未授权';
            } else if (value === 1) {
              return '已授权';
            } else if (value === 2) {
              return '授权失败';
            } else if (value === 3) {
              return '授权错误';
            } else if (value === 4) {
              return '授权失效';
            }
          },
          ptFormatter(row, column) {
            let value = row.platformType;
            if (value === 0) {
              return '抖音';
            } else if (value === 1) {
              return '快手';
            } else if (value === 2) {
              return '西瓜';
            } else if (value === 3) {
              return '视频号';
            } else if (value === 4) {
              return '百家号';
            } else if (value === 5) {
              return '小红书';
            } else if (value === 6) {
              return '微博';
            } else {
              return value;
            }
          },
          atFormatter(row, column){
            let value = row.accountType;
            if (value === 0) {
              return '普通号';
            } else if (value === 1) {
              return '企业号';
            }
          },
          tabCellStyle({row, column, rowIndex, columnIndex}){
            let value = row.loginStatus;
            let cssStr = 'text-align:center;';
            if(columnIndex===4) {
              if (value === 1) {//已授权
                cssStr += 'color:green';
              } else if (value === 2 || value === 3 || value === 4) {
                cssStr += 'color:red';
              }
            }
            return cssStr;
          }
        }
    }
</script>

<style scoped>
    .divAutoTips{
        height: 28px;line-height: 28px
    }
    .divCompanyAutoTips{
      height: 28px;line-height: 28px;margin-top: 10px;
    }
    .autoTips{
        color: #67c23a;font-size: 16px;padding-right: 10px
    }

</style>