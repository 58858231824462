<template>
	<div id="app">
		<div id="header">
			<div class="headerCss">{{currentSession.name}}</div>
			<div class="tipsCss"> tips:仅支持消息回复，在用户未发送下一条消息前，24 小时 内可向用户发送 6 条消息</div>
		</div>

		<div id="message" :style="`height:${heightV}px`" v-scroll-bottom="sessions">
			<ul v-if="currentSession.id==item.id" v-for="item in sessions">
				<li v-for="entry in item.messages">
					<p class="time">
						<span>{{timeFormatter(entry.sendTime,"yyyy/MM/dd hh:mm")}}</span>
					</p>
					<div class="main" :class="{self:entry.self}">
<!--						<img class="avatar" :src="entry.self ? curSelChat.headImg : item.avatar" alt=""  shape="circle">-->
            <el-avatar class="avatar" :src="entry.self ? curSelChat.headImg : item.avatar"  shape="circle"></el-avatar>
            <p class="text"><span v-html="showEmoj(entry.content)"></span></p>
					</div>
				</li>
			</ul>
		</div>
	</div>

</template>

<script>
import {mapState} from 'vuex'
import filter from "@/utils/filter";

export default {
  name: 'message',
  data () {
    return {
    	heightV:window.innerHeight-340,
      curSelChat: localStorage.getItem("curSelChat")?JSON.parse(localStorage.getItem("curSelChat")):null,
    }
  },
  computed:mapState([
  	'sessions',
  	'currentSession'
  ]),
  created() {
    // console.log(window.innerHeight)
  },
  watch:{
    heightV(val){ //监听屏幕高度变化
      // console.log("val:"+val)
      // this.heightV = val
      if(val<160){
        this.heightV =160
      }else{
        this.heightV = val;
      }
      // var oIframe = document.getElementById(divId);
      // oIframe.style.height = (Number(val)-120) + 'px'; //'120'是页面布局调整，可去除
    }
  },
  mounted() {
    var _this = this;
    window.onresize = function(){ // 定义窗口大小变更通知事件
      // console.log("document:"+document.documentElement.clientHeight)
      // console.log("body:"+document.body.clientHeight)
      let ch = document.documentElement.clientHeight;
      if(ch<500){
        _this.heightV =160
      }else{
        _this.heightV = document.documentElement.clientHeight-340;
      }
    };
  },
  methods:{
		timeFormatter(time,format){
			return filter.timeChange(new Date(time*1000),format)
		},
    showEmoj(data){
		  if(!data){
		    return data;
      }
		  let res = data.replace(/\[([\u4e00-\u9fa5]+)\]/g, (res, emoji) => {
        // 检查是否存在对应的表情映射，如果存在则替换为 HTML 代码，否则保持原样
        return filter.emotion(emoji) ? filter.emotion(emoji) : res;
      });
		  return res;
    }
	},
  filters:{
  	time (date) {
      if (date) {
        date = new Date(date);
      }
  		return `${date.getHours()}:${date.getMinutes()}`;
  	}
  },
  directives: {/*这个是vue的自定义指令,官方文档有详细说明*/
    // 发送消息后滚动到底部,这里无法使用原作者的方法，也未找到合理的方法解决，暂用setTimeout的方法模拟
    'scroll-bottom' (el) {
      //console.log(el.scrollTop);
      setTimeout(function () {
        el.scrollTop+=9999;
      },1)
    }
  }
}
</script>

<style lang="scss" scoped>
	.app{
		height: 100%;
	}
	#header{
		border-bottom: solid 1px #cccccc;
		.headerCss{
			font-size: 16px;
			padding-left: 10px;
			padding-top: 10px;
			/*margin-top: 10px;*/
			height: 40px;
			line-height: 40px;
		}
		.tipsCss{
			font-size: 12px;color: firebrick;padding-left: 10px;
			height: 25px;
			line-height: 25px;
		}
	}

#message {
	font-size: 14px;
	padding: 15px;
  /*min-height: 200px;*/
  //min-width: 120px;
  //width: 100%;
  overflow-y: auto;
  ul {
  	list-style-type: none;
    padding-left: 0px;
  	li {
  		margin-bottom: 15px;
  	}
  }
  .time {
  	text-align: center;
  	margin: 7px 0;
  	span {
  		display: inline-block;
  		padding: 0 18px;
  		font-size: 12px;
  		color: #FFF;
  		background-color: #dcdcdc;
  		border-radius: 2px;
  	}
  }
  .main {
  	.avatar {
  		float: left;
  		margin: 0 10px 0 0;
  		border-radius: 3px;
  		width: 30px;
  		height: 30px;

  	}
  	.text {
  		display: inline-block;
  		padding: 0 10px;
  		max-width: 80%;
  		background-color: #fafafa;
      border-radius: 4px;
      line-height: 30px;
  	}
  }
  .self {
    text-align: right;
    .avatar {
      float: right;
      margin: 0 0 0 10px;
      border-radius: 3px;
      width: 30px;
      height: 30px;
    }
    .text {
      display: inline-block;
      padding: 0 10px;
      max-width: 80%;
      background-color: #b2e281;
      border-radius: 4px;
      line-height: 30px;
    }
    .emojCss{
      margin-top: 3px;
    }
  }
}
</style>
