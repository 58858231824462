<template xmlns="http://www.w3.org/1999/html">
  <div id="app">
    <div class="sidebar">
      <div id="elPageHeader">
        <i class="el-icon-notebook-1" style="color: #409EFF;padding-right: 5px;" ></i>
        <span style="font-size: 14px;font-weight: bold">评论消息</span>
        <div style="font-size: 12px;color: #909399;position: absolute;top: 5px;left: 100px">只保存7天内的评论数据</div>
      </div>
<!--      <el-row class="elRow">-->
<!--        <el-button type="primary" size="mini">全部状态</el-button>-->
<!--        <el-button class="otherBtn" size="mini">未沟通</el-button>-->
<!--        <el-button class="otherBtn"  size="mini">已沟通</el-button>-->
<!--        <el-button class="otherBtn"  size="mini">无意向</el-button>-->
<!--        <el-button class="otherBtn"  size="mini">有意向</el-button>-->
<!--        <el-button class="otherBtn"  size="mini">已合作</el-button>-->
<!--      </el-row>-->
<!--      <el-row class="elRow">-->
<!--        <el-button type="primary" size="mini">全部标签</el-button>-->
<!--        <el-button size="mini" class="addBtn" style=""  icon="el-icon-circle-plus-outline">添加标签</el-button>-->

<!--      </el-row>-->
<!--      <el-row class="elRow">-->
<!--        <el-button size="mini" type="primary" style="padding-left: 5px">不限电话/微信</el-button>-->
<!--        <el-button size="mini"  class="otherBtn" style="padding-left: 10px">有电话/微信</el-button>-->

<!--      </el-row>-->
      <ul id="list" style="list-style-type: none;margin-left: 0px">
        <li v-for="item in this.commentList" :class="{ active: item == curSelCommentUser }" v-on:click="changeCurSelChat(item)"
            style="position: relative;height: 80px"><!--   :class="[item.id === currentSession ? 'active':'']" -->
          <el-badge  is-dot v-if="item.readStatus==0?true:false">
            <el-avatar class="avatar" :src="item.avatar" :alt="item.nick_name" shape="circle"></el-avatar>
          </el-badge>
          <el-avatar v-else class="avatar" :src="item.avatar" :alt="item.nick_name" shape="circle"></el-avatar>
          <div class="divCss">
            <p class="name">
              {{ item.nick_name }}
<!--              <span class="potCss"><el-badge is-dot class="item" type="warning" style="margin-bottom: 0px ;margin-top: 5px"></el-badge>&nbsp;未沟通</span>-->
            </p>
            <p class="msgCss">{{contentFormatter(item)}}</p>
          </div>
          <div class="timeCss">{{timeFormatter(item.create_time,"yyyy/MM/dd hh:mm")}}</div>
        </li>
        <div style="text-align: center"> <el-button type="text" size="mini" @click="loadMore">加载更多</el-button></div>
      </ul>

    </div>
  </div>
</template>

<script>

import request from "@/utils/request";
import ElementUI from "element-ui";
import {mapState} from "vuex";
import filter from "@/utils/filter";

export default {
  name: "ChatList",
  data () {
    return {
      cursor:1
      // commentList:[],
    }
  },
  components:{

  },
  computed: mapState([
          'commentList',
    'curSelCommentUser'
  ]),
  mounted() {
    this.$store.dispatch('initCommentData');
  },
  methods: {
    contentFormatter(item){
      if(item&&item.content&&item.content.length>0){
        let msg =  item.content;
        if(msg.length>26){
          msg = msg.substring(0,26)+"...";
        }
        return msg;
      }else{
        return '内容不可见';
      }
    },
    timeFormatter(time,format){
      return filter.timeChange(new Date(time*1000),format)
    },
    changeCurSelChat(curSelCommentUser) {
      this.$store.commit('changCommentUser', curSelCommentUser);
      this.$router.replace('/chat/comment')
    },
    loadMore() {
      this.$store.commit('loadMoreComment', ++this.cursor);
    }
  }
}
</script>
<style lang="scss" scoped>
#app {

  .sidebar {
    float: left;
    color: #f4f4f4;
    background-color: #2e3238;
    width: 100%;
    height: 100%;
    //min-height:600px;
    min-width: 380px;
    border-radius: 10px;
    overflow: auto;
  }

  #list {
    height: 100%;
    li {
      font-size: 14px;
      padding: 15px 15px;
      border-bottom: 1px solid #292C33;
      cursor: pointer;

      &:hover {
        background-color: rgba(255, 255, 255, 0.03);
      }
    }

    li.active { /*注意这个是.不是冒号:*/
      background-color: rgba(255, 255, 255, 0.1);
    }

    .avatar {
      //border-radius: 2px;
      width: 50px;
      height: 50px;
      //margin-left: 15px;
      margin-top: 0px;
      vertical-align: middle;
    }

    .name {
      //font-size: 15px;
      margin-bottom: 0px;
    }
  }
  .msgCss {
    margin-top: 5px;
    font-size: 13px;
    color: #909399;
  }

  .divCss {
    position: absolute;
    top: 5px;
    left: 80px
  }
  .potCss{
    margin-left: 20px;font-size: 12px;color: #909399;
  }
  .timeCss{
    position: absolute;
    top: 50px;
    right: 20px;
    font-size: 12px;color: #909399;
  }
  .elRow{
    margin: 20px 0px
  }
  .elRow button{
    margin-left: 30px;width: 92px;font-size: 13px;
  }
  .otherBtn{
    color: #f4f4f4;
    background-color: #304156;
    border-color:  #304156;
  }
  .addBtn{
    border: solid 1px #409EFF;background-color: transparent;color: #f4f4f4;padding-left: 10px;
  }
  .el-badge__content.is-fixed {
    position: absolute;
    top: -10px;
    right: 20px;
    transform: translateY(-50%) translateX(100%);
  }
}
</style>