<template>
<div id="app">
  <div style="margin-bottom: 10px">
    <el-alert
      title="每个条件为独立触发。（观看数、互动数、粉丝数）为一次性触发，新增(观看数、互动数、粉丝数)与消息通知条件为每日触发"
      type="warning" :closable="false"
      show-icon>
  </el-alert>
  </div>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="数据通知" name="first">
      <div style="margin-bottom: 10px" class="mg-10">
        <span>视频观看数</span>
        <el-switch style="padding-left: 10px"
            v-model="watchSw">
        </el-switch>
        <div v-show="watchSw" class="mg-10">
          <el-form v-for="item in watchData" :inline="true" size="mini" :model="item" class="demo-form-inline">
            <el-form-item label="当视频观看数超过">
              <el-input v-model="item.condValue" type="number"></el-input>
            </el-form-item>
            <el-form-item label="发送通知（需扫码绑定公众号）">&nbsp;
<!--              <el-input v-model="watchNum" type="number"></el-input>-->
            </el-form-item>
<!--            <el-form-item label="通过">-->
<!--              <el-select v-model="form.region" placeholder="请选择通知方式">-->
<!--                <el-option label="邮件" value="shanghai"></el-option>-->
<!--                <el-option label="短信" value="beijing"></el-option>-->
<!--                <el-option label="公众号" value="beijing"></el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
            <el-form-item>
              <span @click="addWatchItem" class="el-icon-circle-plus-outline itemBtnCss"></span>
              <span v-show="watchData.length>1?true:false" @click="delWatchItem(item)" class="el-icon-remove-outline ml-5 itemBtnCss"></span>
            </el-form-item>
          </el-form>
          </div>
      </div>
      <div style="margin-bottom: 10px" class="mg-10">
        <span>视频互动数</span>
        <el-switch style="padding-left: 10px"
                   v-model="interactSw">
        </el-switch>
        <div class="mg-10" v-show="interactSw">
          <el-form v-for="item in interactData" :inline="true" size="mini" :model="item" class="demo-form-inline">
            <el-form-item label="当视频">
              <el-select v-model="item.condType">
                <el-option label="点赞" value="digg"></el-option>
                <el-option label="评论" value="comment"></el-option>
<!--                <el-option label="公众号" value="beijing"></el-option>-->
              </el-select>
            </el-form-item>
            <el-form-item label="超过">
              <el-input v-model="item.condValue" type="number"></el-input>
            </el-form-item>
            <el-form-item label="发送通知">&nbsp;
              <!--              <el-input v-model="watchNum" type="number"></el-input>-->
            </el-form-item>
            <el-form-item>
              <span @click="addInteractItem" class="el-icon-circle-plus-outline itemBtnCss"></span>
              <span v-show="interactData.length>1?true:false" @click="delInteractItem(item)" class="el-icon-remove-outline ml-5 itemBtnCss"></span>
            </el-form-item>
          </el-form>
      </div>
      </div>
      <div style="margin-bottom: 10px;">
        <span>账号粉丝数</span>
        <el-switch style="padding-left: 10px"
                   v-model="fansSw">
        </el-switch>
        <div class="mg-10" v-show="fansSw">
          <el-form v-for="item in fansData" :inline="true" size="mini" :model="item" class="demo-form-inline">
            <el-form-item label="当账号粉丝数超过">
              <el-input v-model="item.condValue" type="number"></el-input>
            </el-form-item>
            <el-form-item label="发送通知">&nbsp;
              <!--              <el-input v-model="watchNum" type="number"></el-input>-->
            </el-form-item>
            <el-form-item>
              <span @click="addFansItem" class="el-icon-circle-plus-outline itemBtnCss"></span>
              <span v-show="fansData.length>1?true:false" @click="delFansItem(item)" class="el-icon-remove-outline ml-5 itemBtnCss"></span>
            </el-form-item>
          </el-form>
      </div>
      </div>
      <div style="margin-bottom: 10px">
        <span>新增观看数</span>
        <el-switch style="padding-left: 10px"
                   v-model="addWatchSw">
        </el-switch>
        <div class="mg-10" v-show="addWatchSw">
          <el-form v-for="item in addWatchData" :inline="true" size="mini" :model="item" class="demo-form-inline">
            <el-form-item label="当新增观看数超过">
              <el-input v-model="item.condValue" type="number"></el-input>
            </el-form-item>
            <el-form-item label="发送通知">
              <!--              <el-input v-model="watchNum" type="number"></el-input>-->
            </el-form-item>
      <el-form-item>
        <span @click="addNewWatchItem" class="el-icon-circle-plus-outline itemBtnCss"></span>
        <span v-show="addWatchData.length>1?true:false" @click="delNewWatchItem(item)" class="el-icon-remove-outline ml-5 itemBtnCss"></span>
      </el-form-item>
          </el-form>
      </div>
      </div>
      <div style="margin-bottom: 10px">
        <span>新增互动数</span>
        <el-switch style="padding-left: 10px"
                   v-model="addInteractSw">
        </el-switch>
        <div class="mg-10" v-show="addInteractSw">
          <el-form v-for="item in addInteractData" :inline="true" size="mini" :model="item" class="demo-form-inline">
            <el-form-item label="当视频新增">
              <el-select v-model="item.condType">
                <el-option label="点赞" value="digg"></el-option>
                <el-option label="评论" value="comment"></el-option>
                <!--                <el-option label="公众号" value="beijing"></el-option>-->
              </el-select>
            </el-form-item>
            <el-form-item label="超过">
              <el-input v-model="item.condValue" type="number"></el-input>
            </el-form-item>
            <el-form-item label="发送通知">&nbsp;
              <!--              <el-input v-model="watchNum" type="number"></el-input>-->
            </el-form-item>
            <el-form-item>
              <span @click="addNewInteractItem" class="el-icon-circle-plus-outline itemBtnCss"></span>
              <span v-show="addInteractData.length>1?true:false" @click="delNewInteractItem(item)" class="el-icon-remove-outline ml-5 itemBtnCss"></span>
            </el-form-item>
          </el-form>
      </div>
      </div>
      <div style="margin-bottom: 10px">
        <span>新增粉丝数</span>
        <el-switch style="padding-left: 10px"
                   v-model="addFansSw">
        </el-switch>
        <div class="mg-10" v-show="addFansSw">
          <el-form v-for="item in addFansData" :inline="true" size="mini" :model="item" class="demo-form-inline">
            <el-form-item label="新增粉丝数超过">
              <el-input v-model="item.condValue" type="number"></el-input>
            </el-form-item>
            <el-form-item label="发送通知">&nbsp;
            </el-form-item>
            <el-form-item>
              <span @click="addNewFansItem" class="el-icon-circle-plus-outline itemBtnCss"></span>
              <span v-show="addFansData.length>1?true:false" @click="delNewFansItem(item)" class="el-icon-remove-outline ml-5 itemBtnCss"></span>
            </el-form-item>
          </el-form>
        </div>
        </div>
    </el-tab-pane>
    <el-tab-pane label="消息通知" name="second">
      <div style="margin-bottom: 10px;">
        <span>评论通知</span>
        <el-switch style="padding-left: 10px"
                   v-model="commentSw">
        </el-switch>
        <div class="mg-10" v-show="commentSw">
          <el-form v-for="item in commentData" :inline="true" size="mini" :model="item" class="demo-form-inline">
            <el-form-item label="当评论触发关键词">
              <el-input v-model="item.condValue" type="text" placeholder="设置关键词,可为空"></el-input>
            </el-form-item>
            <el-form-item label="发送通知">&nbsp;
              <!--              <el-input v-model="watchNum" type="number"></el-input>-->
            </el-form-item>
            <el-form-item>
              <span @click="addCommentItem" class="el-icon-circle-plus-outline itemBtnCss"></span>
              <span v-show="commentData.length>1?true:false" @click="delCommentItem(item)" class="el-icon-remove-outline ml-5 itemBtnCss"></span>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div style="margin-bottom: 10px;">
        <span>私信通知</span>
        <el-switch style="padding-left: 10px"
                   v-model="directSw">
        </el-switch>
        <div class="mg-10" v-show="directSw">
          <el-form v-for="item in directData" :inline="true" size="mini" :model="item" class="demo-form-inline">
            <el-form-item label="当私信触发关键词">
              <el-input v-model="item.condValue" type="text" placeholder="设置关键词,可为空"></el-input>
            </el-form-item>
            <el-form-item label="发送通知">&nbsp;
              <!--              <el-input v-model="watchNum" type="number"></el-input>-->
            </el-form-item>
            <el-form-item>
              <span @click="addDirectItem" class="el-icon-circle-plus-outline itemBtnCss"></span>
              <span v-show="directData.length>1?true:false" @click="delDirectItem(item)" class="el-icon-remove-outline ml-5 itemBtnCss"></span>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-tab-pane>
  </el-tabs>
  <div>
    <el-button type="primary" @click="handleSave">保存</el-button>
  </div>
</div>
</template>

<script>
import common from "@/utils/common";

export default {
  name: "NoticeSet",
  data() {
    return {
      watchSw: false,
      interactSw: false,
      fansSw: false,
      addWatchSw: false,
      addInteractSw: false,
      addFansSw: false,
      commentSw: false,
      directSw: false,
      watchData:[{noticeType:"watch",condValue:10000}],
      interactData:[{noticeType:"interact",condType:'digg',condValue:10000}],
      fansData:[{noticeType:"fans",condValue:10000}],
      addWatchData:[{noticeType:"add_watch",condValue:10000}],
      addInteractData:[{noticeType:"add_interact",condType:'digg',condValue:10000}],
      addFansData:[{noticeType:"add_fans",condValue:10000}],
      commentData:[{noticeType:"comment_msg",condValue:""}],
      directData:[{noticeType:"direct_msg",condValue:""}],
      activeName: 'first'
    };
  },
  created(){
    this.loadData();
  },
  methods: {
    addNewWatchItem(){
      let item={}
      item.noticeType='add_watch'
      item.condValue=10000
      this.addWatchData.push(item)
    },
    delNewWatchItem(item){
      common.removeArray(this.addWatchData,item)
    },
    addInteractItem(){
      let item={}
      item.noticeType='interact'
      item.condType='digg'
      item.condValue=10000
      this.interactData.push(item)
    },
    delInteractItem(item){
      common.removeArray(this.interactData,item)
    },
    addFansItem(){
      let item={}
      item.noticeType='fans'
      item.condValue=10000
      this.fansData.push(item)
    },
    delFansItem(item){
      common.removeArray(this.fansData,item)
    },
    addNewInteractItem(){
      let item={}
      item.noticeType='add_interact'
      item.condType='digg'
      item.condValue=10000
      this.addInteractData.push(item)
    },
    delNewInteractItem(item){
      common.removeArray(this.addInteractData,item)
    },
    addNewFansItem(){
      let item={}
      item.noticeType='add_fans'
      item.condValue=10000
      this.addFansData.push(item)
    },
    delNewFansItem(item){
      common.removeArray(this.addFansData,item)
    },
    addWatchItem(){
      let item={}
      item.noticeType='watch'
      item.condValue=10000
      this.watchData.push(item)
    },
    delWatchItem(item){
      common.removeArray(this.watchData,item)
    },
    addCommentItem(){
      let item={}
      item.noticeType='comment_msg'
      item.condValue=''
      this.commentData.push(item)
    },
    delCommentItem(item){
      common.removeArray(this.commentData,item)
    },
    addDirectItem(){
      let item={}
      item.noticeType='direct_msg'
      item.condValue=''
      this.directData.push(item)
    },
    delDirectItem(item){
      common.removeArray(this.directData,item)
    },

    handleSave(){
      let datalist = [];
      if(this.watchData&&this.watchData.length>0){
        for(let i =0;i<this.watchData.length;i++){
          let item = this.watchData[i]
          item.noticeSwitch=this.watchSw;
          datalist.push(item)
        }
      }
      if(this.addWatchData&&this.addWatchData.length>0){
        for(let i =0;i<this.addWatchData.length;i++){
          let item = this.addWatchData[i]
          item.noticeSwitch=this.addWatchSw;
          datalist.push(item)
        }
      }
      if(this.interactData&&this.interactData.length>0){
        for(let i =0;i<this.interactData.length;i++){
          let item = this.interactData[i]
          item.noticeSwitch=this.interactSw;
          datalist.push(item)
        }
      }
      if(this.fansData&&this.fansData.length>0){
        for(let i =0;i<this.fansData.length;i++){
          let item = this.fansData[i]
          item.noticeSwitch=this.fansSw;
          datalist.push(item)
        }
      }
      if(this.addInteractData&&this.addInteractData.length>0){
        for(let i =0;i<this.addInteractData.length;i++){
          let item = this.addInteractData[i]
          item.noticeSwitch=this.addInteractSw;
          datalist.push(item)
        }
      }
      if(this.addFansData&&this.addFansData.length>0){
        for(let i =0;i<this.addFansData.length;i++){
          let item = this.addFansData[i]
          item.noticeSwitch=this.addFansSw;
          datalist.push(item)
        }
      }
      if(this.commentData&&this.commentData.length>0){
        for(let i =0;i<this.commentData.length;i++){
          let item = this.commentData[i]
          item.noticeSwitch=this.commentSw;
          datalist.push(item)
        }
      }
      if(this.directData&&this.directData.length>0){
        for(let i =0;i<this.directData.length;i++){
          let item = this.directData[i]
          item.noticeSwitch=this.directSw;
          datalist.push(item)
        }
      }
      this.request.post("notice/saveBatch",{
        datalist:datalist
      }).then(res=>{
        if (res.code === '200') {
          this.$message.success('保存成功');
          this.loadData();
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    handleClick(tab, event) {
      // console.log("11", "hello");
    },
    handleChange(value){
      console.log(value)
    },
    loadData(){
      this.request.get("notice/listByUserId").then(res => {
        if (res.code === '200') {
          if(res.data){
            let datalist = JSON.parse(res.data);
            if(datalist&&datalist.length>0){
              for(let i=0;i<datalist.length;i++){
                let item = datalist[i];
                if(item.type=="watch"){
                  this.watchSw=item.sw;
                  this.watchData=item.list;
                }
                if(item.type=="interact"){
                  this.interactSw=item.sw;
                  this.interactData=item.list;
                }
                if(item.type=="fans"){
                  this.fansSw=item.sw;
                  this.fansData=item.list;
                }
                if(item.type=="add_watch"){
                  this.addWatchSw=item.sw;
                  this.addWatchData=item.list;
                }
                if(item.type=="add_fans"){
                  this.addFansSw=item.sw;
                  this.addFansData=item.list;
                }
                if(item.type=="add_interact"){
                  this.addInteractSw=item.sw;
                  this.addInteractData=item.list;
                }
                if(item.type=="direct_msg"){
                  this.directSw=item.sw;
                  this.directData=item.list;
                }
                if(item.type=="comment_msg"){
                  this.commentSw=item.sw;
                  this.commentData=item.list;
                }
              }
            }
          }
        } else {
          this.$message.error(res.msg);
        }
        //console.log(res)
      })
    },
  }
}
</script>

<style scoped>
.itemBtnCss{
  cursor: pointer;font-size: 20px;color: rgb(165, 169, 184)
}
</style>