import axios from 'axios'
import ElementUI from 'element-ui'
import router from '@/router'

const request = axios.create({
    // baseURL: 'http://localhost:8090/',  // 注意！！ 这里是全局统一加上了 后端接口前缀 前缀，后端必须进行跨域配置！ 211.149.178.242
    baseURL: 'https://server.1-123.net/',
    timeout: 30000
})

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    let user= localStorage.getItem("userInfo")?JSON.parse(localStorage.getItem("userInfo")):null;
    if(user){
        config.headers['token'] = user.token;  // 设置请求头
    }

    return config
}, error => {
    return Promise.reject(error)
});

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        let res = response.data;
        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return res
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        //当权限验证不通过的时候给出提示，并返回登录页面
        if(res.code === '401'){
            ElementUI.Message({
                message:res.msg,
                type:'error'
            });
            router.push('/login');
        }
        return res;
    },
    error => {
        console.log('err' + error) // for debug
        if(!error||!error.response||!error.response.code){
            ElementUI.Message({message:'服务器未响应，请稍后再试',type:'error'});
        }else{
            if(error.response.code==504||error.response.code==404){
                ElementUI.Message({message:'服务器未响应，请稍后再试',type:'error'});
            }else if(error.response.code==403){
                ElementUI.Message({message:'权限不足，请联系管理员',type:'error'});
            }else if(error.response.code==401){
                ElementUI.Message({message:'尚未登录，请登录',type:'error'});
            }
        }
        router.push('/login');
        return Promise.reject(error)
    }
)
let base='';
export const postRequest=(url,params)=>{
    return axios({
        method:'post',
        url:`${base}${url}`,
        data:params
    })
}
export const putRequest=(url,params)=>{
    return axios({
        method:'put',
        url:`${base}${url}`,
        data:params
    })
}
export const getRequest=(url,params)=>{
    return axios({
        method:'get',
        url:`${base}${url}`,
        data:params
    })
}
export const deleteRequest=(url,params)=>{
    return axios({
        method:'delete',
        url:`${base}${url}`,
        data:params
    })
}
export default request

