import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import store from "./store";
import 'element-ui/lib/theme-chalk/index.css';
import './assets/global.css';
import request from "@/utils/request";
import moment from 'moment'
import JsonExcel from 'vue-json-excel'

moment.locale('zh-cn')
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.request = request;
Vue.prototype.moment = moment
Vue.component('downloadExcel',JsonExcel)
// 防止el-button重复点击
Vue.directive('preventReClick', {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true;
        el.style.cursur='not-allowed'
        setTimeout(() => {
          el.disabled = false
          el.style.cursor ='pointer'
        }, binding.value || 2000)
      }
    })
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
