<template>
  <div id="card">
    <div id="elPageHeader">
<!--      <el-button icon="el-icon-arrow-left" type="text" @click="backAccountBook()">返回</el-button>-->
      <el-page-header @back="backClick" content="私信消息">
      </el-page-header>
    </div>
    <div>
      <header style="text-align: center">
        <el-avatar class="avatar" v-bind:src="curSelChat.headImg" v-bind:alt="curSelChat.name"></el-avatar>
        <p class="name">{{ curSelChat.name }}</p>
      </header>
      <footer>
        <input class="search" prefix-icon="el-icon-search" type="text" v-model="$store.state.filterKey" placeholder="搜索...">
      </footer>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'card',
  data () {
    return {
      curSelChat: localStorage.getItem("curSelChat")?JSON.parse(localStorage.getItem("curSelChat")):null,
    }
  },
  computed: mapState([
      'curCardPreUrl'
  ]),
  methods:{
    backClick(){
      this.$router.push(this.curCardPreUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
#card {
	/*padding: 12px;*/
  .avatar{
  	width: 40px;
  	height: 40px;
  	vertical-align: middle;/*这个是图片和文字居中对齐*/
  }
  .name {
  	display: inline-block;
  	padding: 10px;
  	margin-bottom: 15px;
  	font-size: 16px;
  }
  .search {
    margin-left: 10px;
  	background: #26292E;
  	height: 30px;
  	line-height: 30px;
  	padding: 0 10px;
  	border: 1px solid #3a3a3a;
  	border-radius: 4px;
  	outline: none;/*鼠标点击后不会出现蓝色边框*/
    color: #FFF;
    font-size: 13px;
    width: 280px;
  }
}
</style>
