import Vue from 'vue'
import Vuex from 'vuex'
import request from "@/utils/request";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Vuex);
// Vue.use(ElementUI);
const now = new Date();

const store = new Vuex.Store({
    state:{
        routes:[],
        sessions:[],//chat界面粉丝列表
        currentSessionIdx:-1,//chat界面当前选中的粉丝的index
        currentSession:{},//chat界面当前选中的粉丝
        filterKey:'',
        accounts:[],//通讯录界面，账号列表,
        curSelAccount:{},//通讯录界面，当前选中的account,
        chatList:[],//私聊列表ChatList,账号列表
        // curSelChat:{},//chatList页面，当前选中的account,
        curCardPreUrl:'direct_list',
        curCommentPreUrl:'comment_list',
        commentList:[],
        curSelCommentUser:{},//当前选中的评论者
        // curSelAccIdx:-1//当前选中的account的index
    },
    mutations:{
        initRoutes(state,data){
            state.routes=data;
        },
        changeCurrentSession (state,currentSession) {
            state.currentSession = currentSession;
            state.currentSessionIdx = state.sessions.findIndex((item) => item.id===currentSession.id);
        },
        addMessage (state,msg) {
            let curSelChat = localStorage.getItem("curSelChat") ? JSON.parse(localStorage.getItem("curSelChat")) : null;
            let curOpenId = "";
            if (curSelChat) {
                curOpenId = curSelChat.openId;
            }
            //存到后台redis，并调用抖音发送接口
            let msgObj = {};
            msgObj.content = msg;
            msgObj.msgType = "text";
            request.post("chat/sendDyDirectMsgHandle",{
                    dyOpenId:curOpenId,
                    fansOpenId:state.currentSession.id,
                    content:JSON.stringify(msgObj)
            }).then(res=>{
                if(res){
                    if(res.code==='200'){
                        state.sessions[state.currentSessionIdx].messages.push({
                            content:msg,
                            sendTime: res.data.sendTime,
                            self:true
                        });
                        let remainCount = !res.data||res.data.replyCount>=6?0:6-res.data.replyCount;
                        state.sessions[state.currentSessionIdx].sendTag=remainCount;
                        state.currentSession.sendTag=remainCount;
                    }else{
                        ElementUI.Message({
                            message:res.msg,
                            type:'error'
                        });
                        // this.$message.error(res.msg);
                    }
                }
            })
        },
        //初始化私聊数据
        INIT_DATA (state,data) {
            // let data = localStorage.getItem('vue-chat-session');
            //console.log(data)
            if (data) {
                state.sessions = data;
            }
        },
        INIT_ACCOUNTS(state,data){
            state.accounts=data;
        },
        changeCurSelAccount (state,curSelAccount) {
            state.curSelAccount = curSelAccount;
            // state.curSelAccIdx = state.accounts.findIndex((item) => item.id===curSelAccId);
        },
        INIT_CHATLIST(state,data){
            state.chatList=data;
        },
        INIT_COMMENTLIST(state,data){
            if(data&&data.length>0){
                state.commentList=data;
            }
        },
        changeCurSelChat (state,curSelChat) {
            state.curSelAccount = curSelChat;
            // state.curSelChat = curSelChat;
            localStorage.setItem("curSelChat",JSON.stringify(curSelChat))
            // state.curSelAccIdx = state.accounts.findIndex((item) => item.id===curSelAccId);
        },
        changeCardPreUrl(state,data){
            state.curCardPreUrl=data;
        },
        changCommentUser(state,curSelCommentUser){
            state.curSelCommentUser=curSelCommentUser;
        },
        loadMoreComment(state,cursor){
            request.get("chat/queryCommentUsersList?curr="+cursor).then(res => {
                if (res) {
                    if (res.code === '200') {
                        let data=res.data;
                        if(data&&data.length>0){
                            if(state.commentList&&state.commentList.length>0){
                                for(let i in data){
                                    state.commentList.push(data[i]);
                                }
                            }else{
                                state.commentList=data;
                            }
                        }
                    } else {
                        ElementUI.Message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                }
            })
        }
    },
    actions:{
        initChatList(context) {
            request.get("account/getMyAccountMsgListByPt?pt=0").then(res => {
                if (res) {
                    if (res.code === '200') {
                        context.commit('INIT_CHATLIST', res.data)
                    } else {
                        ElementUI.Message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                }
            })
        },
        initAccounts(context) {
            request.get("account/getMyAccountListByPt?pt=0").then(res => {
                if (res) {
                    if (res.code === '200') {
                        context.commit('INIT_ACCOUNTS', res.data)
                    } else {
                        ElementUI.Message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                }
            })
        },
        initData(context) {
            let curSelChat = localStorage.getItem("curSelChat") ? JSON.parse(localStorage.getItem("curSelChat")) : null;
            let curOpenId = "";
            if (curSelChat) {
                curOpenId = curSelChat.openId;
            }
            request.get("chat/queryDyFansDirectMsgList?dyOpenId=" + curOpenId).then(res => {
                if (res) {
                    if (res.code === '200') {
                        context.commit('INIT_DATA', res.data)
                    } else {
                        ElementUI.Message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                }
            })
        },
        initCommentData(context) {
            request.get("chat/queryCommentUsersList?curr=1").then(res => {
                if (res) {
                    if (res.code === '200') {
                        context.commit('INIT_COMMENTLIST', res.data)
                    } else {
                        ElementUI.Message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                }
            })
        }
    }
})

store.watch(function (state) {
    return state.sessions
},function (val) {
    // console.log('CHANGE: ', val);
    // localStorage.setItem('vue-chat-session', JSON.stringify(val));
},{
    deep:true/*这个貌似是开启watch监测的判断,官方说明也比较模糊*/
})


export default store;