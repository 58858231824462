import { render, staticRenderFns } from "./ChatList.vue?vue&type=template&id=1c981c7a&scoped=true&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml&"
import script from "./ChatList.vue?vue&type=script&lang=js&"
export * from "./ChatList.vue?vue&type=script&lang=js&"
import style0 from "./ChatList.vue?vue&type=style&index=0&id=1c981c7a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c981c7a",
  null
  
)

export default component.exports