const common = {
    removeArray(_arr, _obj) {
        let length = _arr.length
        for (let i = 0; i < length; i++) {
            if (_arr[i] === _obj) {
                if (i === 0) {
                    _arr.shift() //删除并返回数组的第一个元素
                    return _arr
                } else if (i === length - 1) {
                    _arr.pop()  //删除并返回数组的最后一个元素
                    return _arr
                } else {
                    _arr.splice(i, 1) //删除下标为i的元素
                    return _arr
                }
            }
        }
    },
    /** 从数组中获取指定对象索引 */
    getArrayIndex (_arr,_obj) {
    let len = _arr.length;
    for(let i = 0; i < len; i++)
    {
        if(_arr[i] == _obj)
        {
            return parseInt(i);
        }
    }
    return -1;
},
    base64UrlEncode(str) {
        let base64 = btoa(str);
        return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
    },
}
export default common;
