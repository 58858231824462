<template>
  <div id="app">
    <div class="mg-10"><el-input style="width: 200px" suffix-icon="el-icon-search" placeholder="请输入关键词" v-model="searkeyContent"></el-input>
      <el-button class="ml-5" type="primary" @click="load">查询</el-button>
      <el-button class="ml-5" type="warning" @click="reset">重置</el-button></div>
    <el-table :data="tableData" max-height="700px" border stripe  :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
      <el-table-column prop="commentContent" label="互动消息" width="160">
      </el-table-column>
      <el-table-column prop="replyType" label="互动类型" width="80" :formatter="replyTypeFormatter">
      </el-table-column>
      <el-table-column prop="commentTime" label="互动时间" :formatter="commentTimeFormatter" width="160">
      </el-table-column>
      <el-table-column prop="ptType" label="平台" width="80" :formatter="ptTypeFormatter">
      </el-table-column>
      <el-table-column prop="hitReplyContent" label="自动回复内容" width="160">
      </el-table-column>
      <el-table-column prop="replyTime" label="回复时间" :formatter="replyTimeFormatter"  width="160">
      </el-table-column>
      <el-table-column prop="hitKeyWords" label="命中关键词" width="120">
      </el-table-column>
      <el-table-column prop="defaultStatus" label="是否默认回复" :formatter="defaultStatusFormatter">
      </el-table-column>
    </el-table>
    <div class="pd-10">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import filter from "@/utils/filter";

export default {
  name: "AutoReplyRecord",
  data() {
    return {
      tableData: [],
      total: 0,
      pageNum: 0,
      pageSize: 10,
      searkeyContent: "",
    }
  },
  created(){
    this.load();
  },
  methods: {
    reset() {
      this.searkeyContent = "";
      this.load();
    },
    load() {
      this.request.get("hitKeyWordsRecord/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          searkeyContent: this.searkeyContent
        }
      }).then(res => {
        if (res.code === '200') {
          this.tableData = res.data.records;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
        //console.log(res)
      })
    },
    handleSizeChange(v) {
      // console.log(v)
      this.pageSize = v;
      this.load();
    },
    handleCurrentChange(v) {
      // console.log(v)
      this.pageNum = v;
      this.load();
    },
    ptTypeFormatter(row, column) {
      let value = row.ptType;
      if (value === 0) {
        return '抖音';
      } else if (value === 1) {
        return '快手';
      } else if (value === 2) {
        return '西瓜';
      } else if (value === 3) {
        return '视频号';
      } else if (value === 4) {
        return '百家号';
      } else if (value === 5) {
        return '小红书';
      } else if (value === 6) {
        return '微博';
      } else {
        return value;
      }
    },
    defaultStatusFormatter(row){
      let value = row.defaultStatus;
      if (value) {
        return '是';
      } else {
        return '否';
      }
    },
    replyTimeFormatter(row, column){
      return filter.formatDate(row.replyTime,"YYYY-MM-DD HH:mm:ss")
    },
    commentTimeFormatter(row, column){
      return filter.formatDate(row.commentTime,"YYYY-MM-DD HH:mm:ss")
    },
    replyTypeFormatter(row){
      let value = row.replyType;
      if (value===0) {
        return '评论';
      } else if (value===1) {
        return '私信';
      }
    },
  }
}
</script>

<style scoped>

</style>