<template>
  <div style="line-height: 60px;font-size: 12px;display: flex">
    <div style="flex: 1;font-size: 18px">
        <span :class="collapseBtnClass" style="cursor:pointer" @click="collapse"></span>

<!--    <div style="margin-bottom: 30px;display: inline-block">-->
      <el-breadcrumb separator="/" style="display: inline-block;margin-left: 10px">
        <el-breadcrumb-item :to="{ path: '/home' }" v-for="item in paths">首页</el-breadcrumb-item>
<!--        <el-breadcrumb-item><a href="/">活动管理</a></el-breadcrumb-item>-->
      </el-breadcrumb>
<!--    </div>-->
    </div>
    <el-dropdown style="width:150px;cursor: pointer;text-align: right">
        <span>{{userInfo.username}}</span>
        <i class="el-icon-arrow-down" style="margin-left: 5px"></i>
        <el-dropdown-menu slot="dropdown">
<!--            <el-dropdown-item>个人信息</el-dropdown-item>-->
            <el-dropdown-item>
                <span @click="logout">退出</span>
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
    </div>
</template>

<script>
    export default {
        name: "Header",
        props:{
            collapseBtnClass:String,
            collapse:Function
        },
      data(){
          return{
              userInfo:localStorage.getItem("userInfo")?JSON.parse(localStorage.getItem("userInfo")):{},
            paths:[]
          }
      },
        methods:{
            logout(){
                this.$router.push('/login')
                localStorage.removeItem('userInfo')
                this.$message.success("退出成功")
            }
        }
    }
</script>

<style scoped>

</style>