<template>
<div id="app">
  <div><span class="el-icon-success authSuccessTips"></span></div>
  <div><h2>授权成功</h2></div>
  <div style="margin-bottom: 20px;">
    <el-form size="mini" style="width: 100px;margin: 0px auto 40px auto;" >
    <el-form-item label="头像：" style="text-align: center">
      <el-avatar class="avatar" v-bind:src="headImg"></el-avatar>
    </el-form-item>
      <el-form-item label="昵称：" style="width: 300px;text-align: left">
        <span>{{name}}</span>
      </el-form-item>
  </el-form>
  </div>
  <div style="margin-bottom: 20px">授权账号，可精细化分析视频发布数据，快去体验吧～</div>
  <div>
<!--    <el-button @click="clickCancel">取消</el-button>-->
    <el-button type="primary" @click="continueAuth">继续授权</el-button></div>
</div>
</template>

<script>

import common from "@/utils/common";

export default {
  name: "AuthSuccess",
  data(){
    return{
      authUrl:"",
      name:"",
      headImg:"../assets/images/2.png",
      accountType:0,//1-企业号 0-普通号
      ptType:"0",//平台类型
      userInfo:localStorage.getItem("userInfo")?JSON.parse(localStorage.getItem("userInfo")):{},
    }
  },
  mounted() {
    this.name = this.$route.query.name;
    this.headImg = this.$route.query.headImg;
    this.accountType = this.$route.query.accountType;
    this.ptType = this.$route.query.ptType;
  },
  methods: {
    clickCancel(){
      let elDialog = window.parent.document.getElementById("elDialog");
      this.$refs.myDialog.close();
    },
    continueAuth(){
      if(this.ptType=="0"){
        this.dyAuth();
      }else if(this.ptType=="1"){
        this.ksAuth();
      }
      let iframe = window.parent.document.getElementById("map");
      // const iframe = document.getElementById('map');
      // this.$store.commit('changeAuthIframeUrl', '/account/account_auth?${new Date().getTime()');
      //iframe.reload(true)
      // iframe.key=iframe.key+1;
      iframe.src=this.authUrl;
      // this.$store.commit('changeAuthIframeUrl', '//account/account_auth');
      // window.parent.postMessage({ message: 'Hello, parent!' }, '*')
      // window.parent.postMessage("hello，我即将传给父项目", "*");
      // console.log("sss"+this.authIframeUrl)
      // this.$parent.ksAuth()
    },
    dyAuth() {
      var fromurl = "https://server.1-123.net/callback/accountAuthCallback";
      var stateJson = {};
      stateJson.userId = this.userInfo.userId;
      stateJson.platform = 0;
      stateJson.platformType = 0;
      stateJson.accountType = this.accountType;
      let state = common.base64UrlEncode(encodeURIComponent(JSON.stringify(stateJson))); // encode后拼接到授权链接上 ,user.intention,im.direct_message,im.microapp_card,tool.image.upload,im.message_card
      var url="https://open.douyin.com/platform/oauth/connect/?client_key=aw62rc3o61yi42it&state="+state+"&response_type=code&scope=fans.data.bind,data.external.user,data.external.item,user_info,video.list.bind,video.data.bind,item.comment";
      if(this.accountType===1){
        url+=",im.direct_message,im.microapp_card,tool.image.upload,im.message_card";
      }
      url+="&redirect_uri="+encodeURIComponent(fromurl);
      this.authUrl=url;
    },
    ksAuth(){
      var fromurl = "https://server.1-123.net/callback/accountAuthCallback";
      var stateJson = {};
      stateJson.userId = this.userInfo.userId;
      stateJson.platform = 0;
      stateJson.platformType = 1;
      stateJson.accountType = 0;
      let state = common.base64UrlEncode(encodeURIComponent(JSON.stringify(stateJson))); // encode后拼接到授权链接上
      var url = "https://open.kuaishou.com/oauth2/connect?app_id=ks687080419384462650&scope=user_info,user_video_info&response_type=code&ua=pc&state="+state+"&redirect_uri="+encodeURIComponent(fromurl);
      this.authUrl=url;
    },
  },

}
</script>

<style scoped>
#app{
  margin: 50px auto 0px auto;
  text-align: center;
}
.authSuccessTips{
  color: #67c23a;font-size: 58px;padding-right: 10px
}
.avatar{
  width: 40px;
  height: 40px;
  vertical-align: middle;/*这个是图片和文字居中对齐*/
}
</style>