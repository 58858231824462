<template xmlns="http://www.w3.org/1999/html">
  <div id="app">
    <div class="sidebar">
      <msgList></msgList>
    </div>
<!--    <div class="main">-->
<!--      这边是通讯录详情-->
<!--    </div>-->
  </div>
</template>

<script>

import msgList from "@/components/chat/msgList";

export default {
  name: "ChatList",
  data () {
    return {

    }
  },
  components:{
    msgList,
  },
  mounted() {
    this.$store.dispatch('initChatList');
  },
  methods:{

  }
}
</script>
<style lang="scss" scoped>
  .sidebar, .main {
    height: 100%;
  }
  .sidebar {
    float: left;
    color: #f4f4f4;
    background-color: #2e3238;
    width: 100%;
    height: 100%;
    min-width: 380px;
    overflow: auto;
    border-radius: 10px;
  }
  .main {
    position: relative;
    overflow: hidden;
    background-color: #eee;
  }

</style>