<template>
<div><h1></h1></div>
</template>

<script>
export default {
  name: "Home"
}
</script>

<style scoped>

</style>