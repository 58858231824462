<template>
  <div>
    <div>
      <div id="elPageHeader">
        <i class="el-icon-notebook-1" style="color: #409EFF;padding-right: 5px;" ></i>
        <span style="font-size: 14px;font-weight: bold">私信消息</span>
        <div style="font-size: 12px;color: #909399;position: absolute;top: 5px;left: 100px">当前功能仅限已授权的企业号及其员工号和小程序品牌号及员工号使用</div>
      </div>
<!--      <el-row class="elRow">-->
<!--        <el-button type="primary" size="mini">全部状态</el-button>-->
<!--        <el-button class="otherBtn" size="mini">未沟通</el-button>-->
<!--        <el-button class="otherBtn"  size="mini">已沟通</el-button>-->
<!--        <el-button class="otherBtn"  size="mini">无意向</el-button>-->
<!--        <el-button class="otherBtn"  size="mini">有意向</el-button>-->
<!--        <el-button class="otherBtn" size="mini">已合作</el-button>-->
<!--      </el-row>-->
<!--      <el-row class="elRow">-->
<!--        <el-button type="primary" size="mini">全部标签</el-button>-->
<!--        <el-button plain class="addBtn" size="mini" icon="el-icon-circle-plus-outline">添加标签</el-button>-->

<!--      </el-row>-->
<!--      <el-row class="elRow">-->
<!--        <el-button type="primary" size="mini" style="padding-left: 5px">不限电话/微信</el-button>-->
<!--        <el-button size="mini" class="otherBtn" style="padding-left: 10px">有电话/微信</el-button>-->

<!--      </el-row>-->
    </div>
    <ul id="list" style="list-style-type: none;margin-left: 0px">
      <li v-for="item in chatList" v-on:click="changeCurSelChat(item)"
          style="position: relative;height: 80px"><!--   :class="[item.id === currentSession ? 'active':'']" -->

        <el-badge :value="item.msgHave" v-if="item.msgHave>0?true:false">
          <el-avatar class="avatar" :src="item.headImg" :alt="item.name" shape="circle"></el-avatar>
        </el-badge>
        <el-avatar v-else class="avatar" :src="item.headImg" :alt="item.name" shape="circle"></el-avatar>
        <div class="divCss">
          <p class="name">
            {{ item.name }}
<!--            <span class="potCss"><el-badge is-dot class="item" type="warning" style="margin-bottom: 0px ;margin-top: 5px"></el-badge>&nbsp;未沟通</span>-->
          </p>
          <p class="msgCss">{{item.msgHave>0?"有新未读消息":"暂无消息"}}</p>
        </div>
<!--        <div class="timeCss">2023/09/05 12:05</div>-->
      </li>
    </ul>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'msgList',
  data() {
    return {
      // accounts:[],
      // curSelAccId:-1,
    }
  },
  computed: mapState([
    'chatList'
  ]),
  methods: {
    changeCurSelChat(curSelChat) {
      this.$store.commit('changeCurSelChat', curSelChat);
      this.$store.commit('changeCardPreUrl', 'direct_list');
      this.$router.replace('/chat/chat')
    }
  }
}
</script>

<style lang="scss" scoped>
#list {
  height: 100%;
  overflow-y: visible;

  li {
    font-size: 14px;
    padding: 15px 15px;
    border-bottom: 1px solid #292C33;
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 0.03);
    }
  }

  li.active { /*注意这个是.不是冒号:*/
    background-color: rgba(255, 255, 255, 0.1);
  }

  .avatar {
    //border-radius: 2px;
    width: 50px;
    height: 50px;
    //margin-left: 15px;
    margin-top: 0px;
    vertical-align: middle;
  }

  .name {
    //font-size: 15px;
    margin-bottom: 0px;
  }
}
.msgCss {
  margin-top: 5px;
  font-size: 13px;
  color: #909399;
}

.divCss {
  position: absolute;
  top: 5px;
  left: 80px
}
.potCss{
  margin-left: 20px;font-size: 12px;color: #909399;
}
.timeCss{
  position: absolute;
  top: 50px;
  right: 20px;
  font-size: 12px;color: #909399;
}
.elRow{
  margin: 20px 0px
}
.elRow button{
  margin-left: 30px;width: 92px;font-size: 13px;
}
.otherBtn{
  color: #f4f4f4;
  background-color: #304156;
  border-color:  #304156;
}
  .addBtn{
    border: solid 1px #409EFF;background-color: transparent;color: #f4f4f4;padding-left: 10px;
  }
</style>
