<template>
  <div id="app">
    <el-row :gutter="2" style="height: 100%">
      <el-col :span="4" class="leftElRow">
        <div>
        <el-row>
          <el-col v-for="(item, index) in accountList" :key="item" class="card">
            <el-card>
              <el-link @click="accClick(item.id)" :underline="false" class="text-center elLink">
<!--                <img :src="item.headImg" alt="...">-->
                <el-avatar class="avatar" :src="item.headImg" :alt="item.name" shape="circle"></el-avatar>
                <div class="card-footer">
                  {{item.name.length>9?item.name.substring(0,9)+"...":item.name }}
                </div>
              </el-link >
            </el-card>
          </el-col>
        </el-row>
      </div>
        <!--left layout end -->
      </el-col>
      <el-col :span="20">
        <div>
          <el-row class="elRow">
            统计周期：
            <el-button size="mini" type="primary" plain @click="dateTypeClick(7)">7天</el-button>
            <el-button size="mini" type="primary" plain @click="dateTypeClick(15)">15天</el-button>
            <el-button size="mini" type="primary" plain @click="dateTypeClick(30)">30天</el-button>
          </el-row>
          <!--    <div class="mg-10">-->
          <!--      <el-input style="width: 200px" suffix-icon="el-icon-search" placeholder="请输入账号昵称" v-model="searkeyContent"></el-input>-->
          <!--      <el-button class="ml-5" type="primary" @click="load">查询</el-button>-->
          <!--      <el-button class="ml-5" type="warning" @click="reset">重置</el-button>-->
          <!--    </div>-->

          <el-table max-height="800px" :data="tableData" border stripe :default-sort = "{prop: 'date', order: 'descending'}" :header-cell-style="{'text-align':'center'}"  cell-style="text-align:center;">
            <el-table-column prop="date" label="日期" width="160" :formatter="dateFormatter" sortable>
            </el-table-column>
            <!--      <el-table-column prop="replyContent" label="账号昵称" width="150">-->
            <!--      </el-table-column>-->
            <el-table-column prop="total_fans" label="粉丝总数" width="150"  >
            </el-table-column>
            <el-table-column prop="new_fans" label="新增粉丝数">
            </el-table-column>
            <!--      <el-table-column prop="accounts" label="应用账号" :formatter="accountsFormatter">-->
            <!--      </el-table-column>-->
            <!--      <el-table-column prop="defaultStatus" label="是否默认回复" :formatter="defaultStatusFormatter">-->
            <!--      </el-table-column>-->
            <!--      <el-table-column prop="enableStatus" label="启用/停用" :formatter="enableStatusFormatter">-->
            <!--      </el-table-column>-->
            <!--      <el-table-column prop="createTime" label="创建时间" :formatter="createTimeFormatter">-->
            <!--      </el-table-column>-->
            <!--      <el-table-column label="操作" width="210">-->
            <!--        <template slot-scope="scope">-->
            <!--          <el-button size="small" class="ml-5" type="primary" @click="editModel(scope.row)">编辑 <i class="el-icon-edit"></i></el-button>-->
            <!--          <el-popconfirm-->
            <!--              title="确定要删除吗？" @confirm="deleteData(scope.row.id)"-->
            <!--          >-->
            <!--            <el-button size="small" class="ml-5" type="danger" slot="reference">删除 <i class="el-icon-remove-outline"></i></el-button>-->
            <!--          </el-popconfirm>-->
            <!--        </template>-->
            <!--      </el-table-column>-->
          </el-table>
          <div class="pd-10">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageNum"
                :page-sizes="[30]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import filter from "@/utils/filter";
import request from "@/utils/request";
import ElementUI from "element-ui";

export default {
  name: "FansStat",
  data(){
    return{
      accountId:0,
      dateType:7,
      accountList:[],
      tableData: [],
      total:0,
      pageNum:0,
      pageSize:30,
      // searkeyContent:"",
    }
  },
  created(){
    this.loadAccount();
    // this.load();
  },
  methods: {
    accClick(v){
      this.accountId=v;
      this.load();
    },
    dateFormatter(row, column){
      return filter.formatDate(row.date,"YYYY-MM-DD")
    },
    reset() {
      // this.searkeyContent = "";
      this.load();
    },
    dateTypeClick(v){
      this.dateType=v;
      this.load();
    },
    loadAccount(){
      this.request.get("account/getMyAccountListByPt?pt=0").then(res => {
        if (res) {
          if (res.code === '200') {
            this.accountList= res.data;
          } else {
            this.$message.error(res.msg);
          }
        }
      })
    },
    load(){
      this.request.get("statistics/fansPage", {
        params: {
          accountId: this.accountId,
          dateType: this.dateType,
        }
      }).then(res => {
        if (res.code === '200') {
          this.tableData = res.data.records;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
        //console.log(res)
      })
    },
    handleSizeChange(v) {
      // console.log(v)
      this.pageSize = v;
      this.load();
    },
    handleCurrentChange(v) {
      // console.log(v)
      this.pageNum = v;
      this.load();
    },
  }
}
</script>

<style scoped>
.avatar {
//border-radius: 2px;
  width: 50px;
  height: 50px;
//margin-left: 15px;
  margin-top: 0px;
  vertical-align: middle;
}
.leftElRow{
  /*border: 1px solid red;*/
  height: 100%;
  overflow-y: auto;
}

.elRow{
  margin-bottom: 20px;
}
.card{
  float: left; margin-left: 10px;margin-top: 10px;width: 100px;
}
.card-img-top{
  width: 60px;
}
.card-footer{
  padding-top: 5px;
  width: 60px;
  height: 22px;
  text-align: center;
  font-size: 12px;
}
</style>