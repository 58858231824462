<template>
  <div id="app">
    <div class="sidebar">
      <accountList @load='loadInfo'></accountList>
    </div>
    <div class="main" v-show="checkShow()">
      <div style="text-align: center;margin-top: 20px; border-bottom: solid 1px #cccccc;height: 280px">
        <el-avatar class="avatar" v-bind:src="curSelAccount.headImg" v-bind:alt="curSelAccount.name"></el-avatar>
        <p class="">{{ curSelAccount.name }}</p>
        <div style="margin-top: 50px">
          <el-row :gutter="20">
            <el-col :span="6">
              <div>
                <el-statistic
                    group-separator=","
                    precision="2"
                    value="120"
                    title="点赞"
                ><template slot="formatter">
                  4562
                </template></el-statistic>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <el-statistic title="朋友" value="120">
                  <template slot="formatter">
                    128
                  </template>
                </el-statistic>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <el-statistic title="关注" value="120">
                  <template slot="formatter">
                    456
                  </template>
                </el-statistic>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <el-statistic title="粉丝" value="120">
                  <template slot="formatter">
                    6783
                  </template>
                </el-statistic>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div style="text-align: center;margin-top: 40px;">
        <el-button type="primary" style="margin-right: 20px" @click="toMngUrl('comment')">评论管理</el-button>
        <el-button type="primary" style="margin-right: 20px" @click="toMngUrl('chat')">私信管理</el-button>
        <el-button type="primary" style="margin-right: 20px" >意向用户管理</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import accountList from "@/components/chat/accountList";
import {mapState} from "vuex";

export default {
  name: "AccountBook",
  data () {
    return {
      curSelAccount: {},
    }
  },
  // computed: mapState([
  //   'curSelAccount'
  // ]),
  components:{
    accountList,
  },
  mounted() {
    this.$store.dispatch('initAccounts');
  },
  methods:{
    checkShow(){
      if(this.curSelAccount&&this.curSelAccount.name){
        return true
      }else{
        return false
      }

    },
    loadInfo(data){
      this.curSelAccount = data;
    },
    toMngUrl(url){
      this.$store.commit('changeCardPreUrl', 'account_book');
      this.$store.commit('changeCurSelAccount', this.curSelAccount);
      this.$router.replace(url)
    }
  }
}
</script>
<style lang="scss" scoped>
#app {
  //margin: 20px 20px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
  .sidebar, .main {
    height: 100%;
  }
  .sidebar {
    float: left;
    color: #f4f4f4;
    background-color: #2e3238;
    width: 300px;
  }
  .main {
    position: relative;
    overflow: hidden;
    background-color: #eee;
    //width: 80%;
  }
  .avatar {
    //border-radius: 2px;
    width: 100px;
    height: 100px;
    vertical-align: middle;
  }
  .name {
    display: inline-block;
    margin-left: 15px;
  }
}
</style>