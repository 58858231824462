<template>
  <div id="list">
    <div>
    <input class="search" prefix-icon="el-icon-search" type="text" v-model="$store.state.filterKey" placeholder="搜索...">
    </div>
  	<ul style="padding-left: 0px">
  		<li v-for="item in accounts" :class="{ active: item.id == curSelAccount.id }" v-on:click="changeCurSelAccount(item)"><!--   :class="[item.id === currentSession ? 'active':'']" -->
        <el-avatar class="avatar" :src="item.headImg" :alt="item.name"></el-avatar>
  			<p class="name">{{item.name}}</p>
  		</li>
  	</ul>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'accountList',
  data () {
    return {
      // accounts:[],
      curSelAccount:{},
    }
  },
  computed: mapState([
    'accounts',
    'curSelAccount'
  ]),
  methods:{
    changeCurSelAccount(curSelAccount) {
      this.curSelAccount=curSelAccount;
      this.$emit("load",curSelAccount)
      // this.$store.commit('changeCurSelAccount',curSelAccount);
      // this.$router.replace('/chat/chat')
  	}
  }
}
</script>

<style lang="scss" scoped>
#list {
  height: 100%;
  overflow-y: visible;
	li {
    font-size: 14px;
		padding: 5px 15px;
		border-bottom: 1px solid #292C33;
		cursor: pointer;
		&:hover {
			background-color: rgba(255, 255, 255, 0.03);
		}
	}
  li.active {/*注意这个是.不是冒号:*/
			background-color: rgba(255, 255, 255, 0.1);
	}
	.avatar {
		//border-radius: 2px;
		width: 30px;
		height: 30px;
		vertical-align: middle;
	}
	.name {
		display: inline-block;
		margin-left: 15px;
	}
  .search {
    background: #26292E;
    height: 30px;
    line-height: 30px;
    padding: 0px 10px;
    margin-top: 20px;
    margin-left: 10px;
    border: 1px solid #3a3a3a;
    border-radius: 4px;
    outline: none;/*鼠标点击后不会出现蓝色边框*/
    color: #FFF;
    font-size: 13px;
    width: 280px;
  }
}
</style>
