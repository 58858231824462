<template>
  <div id="usertext">
    <el-input resize="true" type="textarea" :rows="5" placeholder="按 Ctrl + Enter 发送" v-model="content" v-on:keyup.enter.native="addMessage"></el-input>
    <div>
      <el-button type="primary" size="mini" v-on:click="sendMsgClick"
      style="float:right;margin-right: 15px;margin-top: 8px">发送</el-button>
      <span style="float:right;padding-right: 5px;padding-top: 10px" class="remainNumCss">（还可发送 <span style="color: firebrick">{{currentSession.sendTag>=6?0:6- currentSession.sendTag}}</span> 条）</span>
    </div>
    </div>
</template>
<script>
import {mapState} from 'vuex'

export default {
  name: 'usertext',
  data () {
    return {
      content:''
    }
  },
  computed:mapState([
    'currentSession'
  ]),
  methods: {
    sendMsg(){
      if(!this.content||!this.content.length){
        this.$message.warning("不能发送空白信息");
        return;
      }
      if(this.currentSession.sendTag>=6){
        this.$message.warning("用户回复之前，最多只能发送6条消息哦");
        return;
      }
      this.$store.commit('addMessage',this.content);
      this.content='';
    },
    sendMsgClick(){
      this.sendMsg();
    },
  	addMessage (e) {
  		if (e.ctrlKey && e.keyCode ===13 && this.content.length) {
  			this.$store.commit('addMessage',this.content);
  			this.content='';
  		}
  	}
  }
}
</script>

<style lang="scss" scoped>
#usertext {
	position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 170px;
  /*border-top: solid 1px #DDD;*/
  textarea {
  	padding: 10px;
  	width: 100%;
  	height: 100%;
  	border: none;
  	outline: none;
  }
  .remainNumCss{
    font-size: 12px;
    color: #909399;
  }
}
</style>
