<template>
    <div>功能内测中</div>
</template>

<script>
    export default {
        name: "UploadVideo"
    }
</script>

<style scoped>

</style>