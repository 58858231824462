<template>
<div id="main">
  <div><span class="el-icon-warning authFailureTips"></span></div>
  <div><h2>授权失败</h2></div>
  <div style="margin-bottom: 20px">{{reason}}</div>
<!--    <el-button @click="clickCancel">取消</el-button>-->
<!--    <el-button type="primary" @click="continueAuth">继续授权</el-button></div>-->
</div>
</template>

<script>

import common from "@/utils/common";

export default {
  name: "AuthFailure",
  data(){
    return{
      reason:"授权发生错误",
    }
  },
  mounted() {
    this.reason = this.$route.query.reason;
  },
  methods: {

  },

}
</script>

<style scoped>
#main{
  /*margin: 50px auto 0px auto;*/
  /*height: 300px;*/
  text-align: center;
}
.authFailureTips{
  color: #E6A23C;font-size: 58px;padding-right: 10px
}
</style>