<template>
  <div id="app">
    <div class="sidebar">
      <card></card>
      <list></list>
    </div>
    <div class="main">
      <message></message>
      <usertext></usertext>
    </div>
  </div>
</template>

<script>
import card from "@/components/chat/card";
import list from "@/components/chat/list";
import message from "@/components/chat/message";
import usertext from "@/components/chat/usertext";

export default {
  name: "Chat",
  data () {
    return {

    }
  },
  mounted:function() {
    this.$store.dispatch('initData');
  },
  components:{
    card,
    list,
    message,
    usertext
  }
}
</script>
<style lang="scss" scoped>
#app {
  //margin: 20px 20px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: auto;
  border-radius: 10px;
  min-width: 445px;
  min-height: 400px;
  .sidebar, .main {
    height: 100%;
  }
  .sidebar {
    float: left;
    color: #f4f4f4;
    background-color: #2e3238;
    width: 300px;
    overflow: hidden;
  }
  .main {
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    /*min-height: 200px;*/
    background-color: #eee;
  }
}
</style>