<template>
  <div id="app">
    <div class="sidebar">

      <div id="elPageHeader">
<!--        <i class="el-icon-notebook-1" style="color: #409EFF;padding-right: 5px;" ></i>-->
<!--        <span style="font-size: 14px;font-weight: bold">评论管理</span>-->
<!--        <div style="font-size: 12px;color: #909399;position: absolute;top: 5px;left: 100px">当前功能仅限抖音企业号使用</div>-->
        <el-page-header @back="backClick" content="评论消息">
        </el-page-header>
      </div>

      <ul id="list">
        <li v-for="(item, i) in comments"><!--   :class="[item.id === currentSession ? 'active':'']" -->
          <el-avatar class="avatar" :src="item.avatar" :alt="item.nick_name" shape="circle"></el-avatar>

          <div class="divCss">
            <div class="name">
              {{ item.nick_name }} 评论了作品 <el-button type="text" size="mini">{{contentFormatter(item)}}</el-button>
              <el-button v-show="replyBtnShow" type="text" size="mini" style="margin-left: 20px" v-on:click="showReplyInput(item,i)">{{item.inputShow?"收起":"回复"}}</el-button>
            </div>
            <div class="msgCss">{{item.content}}</div>
            <div class="timeCss">{{timeFormatter(item.create_time,"yyyy/MM/dd hh:mm")}}</div>
            <div class="replyContentCss"  v-for="(reply, j) in item.replyData">
              <el-avatar class="avatar" :src="reply.avatar" :alt="reply.nick_name" shape="circle"></el-avatar>
              <div class="divCss">
              <div class="name">
                {{ reply.nick_name }}：
              </div>
              <div class="msgCss">{{reply.content}}</div>
              <div class="timeCss">{{timeFormatter(reply.create_time,"yyyy/MM/dd hh:mm")}}</div>
              </div>
              <div class="clearfix"></div>
            </div>
                      <div class="replyCss" v-if="item.inputShow">
                        <el-input class="inputCss"
                                type="textarea"
                                :rows="4"
                                :placeholder="'回复@'+item.nick_name"
                                v-model="replyTxt"
                                >
                        </el-input>
                        <el-button size="mini" type="primary" v-on:click="replyCommentClick(item,i)">提交</el-button>
                      </div>
          </div>
          <div class="clearfix"></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import {mapState} from "vuex";
import filter from "@/utils/filter";

export default {
  name: "Comment",
  data(){
    return {
      replyTxt:"",
      index:0,
      comments:[],
      replyBtnShow:true,
    }
  },
  computed: mapState([
    'curSelCommentUser','curCommentPreUrl'
  ]),
  mounted() {
    this.initData();
  },
  methods:{
    backClick(){
      this.$router.push(this.curCommentPreUrl)
    },
      contentFormatter(item){
          if(item.videoInfo&&item.videoInfo.title&&item.videoInfo.title.length>0){
            this.replyBtnShow=true;
              let msg =  item.videoInfo.title;
              if(msg&&msg.length>26){
                  msg = msg.substring(0,26)+"...";
              }
              return msg;
          }else{
            this.replyBtnShow=false;
            return '内容不可见';
          }
      },
    timeFormatter(time,format){
      return filter.timeChange(new Date(time*1000),format)
    },
    initData () {
      let curSelChat = localStorage.getItem("curSelChat")?JSON.parse(localStorage.getItem("curSelChat")):null;
      let curOpenId = "";
      if(curSelChat){
        curOpenId = curSelChat.openId;
      }
      request.get("chat/queryCommentListByOpenId",{
        params: {
          fansUserId: this.curSelCommentUser.comment_user_id,
          nextId:0
        }
      }).then(res=>{
        if(res){
          if(res.code==='200'){
            this.comments = res.data;
            for (let i = 0; i < this.comments.length; i++) {
              //给每条评论添加一个属性用于控制评论框的显示与隐藏
              this.$set(this.comments[i], "inputShow", false);
            }
          }else{
            this.$message.error(res.msg);
          }
        }
      })
    },
    //点击回复， 展示评论框
    showReplyInput(item, i) {
      if(!this.comments[i].inputShow){
        this.comments[this.index].inputShow = false;
      }
      this.index = i;
      this.comments[i].inputShow = !this.comments[i].inputShow;
    },
    replyCommentClick(item,i){
      if(!this.replyTxt||!this.replyTxt.length){
        this.$message.warning("不能发送空白信息");
        return;
      }

      request.post("chat/replyDyCommentHandle",{
        dyOpenId:item.authorUserId,
        itemId:item.reply_to_item_id,
        commentId:item.comment_id,
        content:this.replyTxt
      }).then(res=>{
        if(res){
          if(res.code==='200'){
            this.$message.success("回复成功");
            this.comments[i].inputShow=false;
            if(!this.comments[i].replyData){
              this.comments[i].replyData=[]
            }
            this.comments[i].replyData.unshift(res.data);
            this.replyTxt="";
          }else{
            this.$message.error(res.msg);
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

  .sidebar {
    float: left;
    color: #f4f4f4;
    background-color: #2e3238;
    width: 100%;
    height: 100%;
    overflow: auto;
    border-radius: 10px;
    min-width: 450px;
  }

*{
  font-size: 14px;
}
#list {
  height: 100%;
  list-style-type: none;margin-left: 0px;
  li {
    font-size: 14px;
    padding: 15px 15px;
    border-bottom: 1px solid #292C33;
    cursor: pointer;
    //float: left;
    //width: 100%;
    &:hover {
      background-color: rgba(255, 255, 255, 0.03);
    }
  }

  li.active { /*注意这个是.不是冒号:*/
    background-color: rgba(255, 255, 255, 0.1);
  }

  .avatar {
    float: left;
    width: 30px;
    height: 30px;
    margin-top: 5px;
    vertical-align: middle;
  }

  .name {
    //font-size: 15px;
    margin-bottom: 0px;
    color: #909399;
  }
}
.msgCss {
  margin-top: 5px;
  font-size: 13px;
  color: #f4f4f4;
}

.divCss {
  float: left;
  //border: 2px solid red;
  //position: absolute;
  //top: -5px;
  margin-left: 10px;
  //width: 100%;
}
.replyCss{
  margin-top: 8px;
  /*height: 50px;*/
  width: 500px;
  /*border: 1px solid #3a8ee6;*/
  /*background: #909399;*/
}
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}
.inputCss{
  //background-color: red;
  margin-bottom: 10px;
}
.replyContentCss {
  margin-top: 5px;
  font-size: 13px;
  color: #909399;
}
.timeCss{
  margin-top: 5px;
  font-size: 13px;
  color: #909399;
}
</style>